import config from './mergedConfig'
import dom from '~/utils/dom'

const { caretSize } = config
const caretHalfRotatedHeight = (caretSize * Math.sqrt(2)) / 2
let TweenLite = null

/**
 * CanvasTooltip Class
 * initialize() in 'mounted' hook
 * destroy() in 'destroyed' hook
 */
class CanvasTooltip {
  set placement(placement) {
    this.tooltipElement.classList.remove('top', 'bottom')
    this.tooltipElement.classList.add(placement.toLowerCase())
    const caretElement = this.tooltipElement.querySelector('#tooltip-caret')
    if (placement === 'top') {
      caretElement.style.bottom = -caretHalfRotatedHeight
      caretElement.style.top = ''
    } else if (placement === 'bottom') {
      caretElement.style.bottom = ''
      caretElement.style.top = 0
    }
    this._placement = placement
  }

  get placement() {
    return this._placement
  }

  set x(x) {
    const viewportRight = window.scrollX + window.innerWidth
    let left = x - this.tooltipElement.clientWidth / 2
    if (left < 0) {
      left = 0
    }

    const right = left + this.tooltipElement.clientWidth
    if (right > viewportRight) {
      left = viewportRight - this.tooltipElement.clientWidth
    }

    this.left = left
    this._x = x
    this.tooltipElement.style.left = this.left + 'px'
    const caretElement = this.tooltipElement.querySelector('#tooltip-caret')
    caretElement.style.left =
      this._x - this.left - caretHalfRotatedHeight + 'px'
  }

  get x() {
    return this._x
  }

  set y(y) {
    let top =
      this.placement === 'top'
        ? y - this.tooltipElement.clientHeight - caretHalfRotatedHeight
        : y + caretHalfRotatedHeight
    if (top < 0) {
      top = 0
    }

    this.top = top
    this._y = y
    this.tooltipElement.style.top = this.top + 'px'
  }

  get y() {
    return this._y
  }

  initialize() {
    this.tooltipElement =
      this.tooltipElement || document.getElementById(config.id)

    if (this.tooltipElement) {
      return
    }

    this.tooltipElement = document.createElement('div')
    this.tooltipElement.id = config.id
    this.tooltipElement.classList.add(config.class, 'fadeIn')
    this.tooltipElement.style.transition = `opacity ${
      config.animationSpeed
    }s ease-in-out`
    this.tooltipElement.innerHTML =
      '<div class="tooltip-content">' +
      '<table>' +
      '<thead><tr><th id="canvas-tooltip-title"></th></tr></thead>' +
      '<tbody><tr><td id="canvas-tooltip-body"><div class="graph-label"><div class="legend">' +
      '</div></div></td></tr></tbody>' +
      '</table>' +
      `</div><div id="tooltip-caret" class="tooltip-caret"></div>`

    const caretElement = this.tooltipElement.querySelector('#tooltip-caret')
    caretElement.style.width = config.caretSize + 'px'
    caretElement.style.height = config.caretSize + 'px'
    this.tooltipElement.style.opacity = 0

    document.body.appendChild(this.tooltipElement)
  }

  getIsOpen() {
    return (
      this.tooltipElement && parseInt(this.tooltipElement.style.opacity) === 1
    )
  }

  setTooltip({ title, body, position, placement, colors }) {
    this.toggleTooltip()
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
      this.timeoutId = null
    }
    this.setTitle(title)
    this.setBody(body, colors)
    this.setPosition(position, placement) //Position must be set after title and body
    this.toggleTooltip(true)
  }

  setTitle(title = '') {
    if (!this.tooltipElement) return
    const titleEl = this.tooltipElement.querySelector('#canvas-tooltip-title')
    titleEl.innerHTML = title
  }

  setBody(body = '', colors) {
    if (!this.tooltipElement) return
    const bodyEl = this.tooltipElement.querySelector('.legend')
    if (body.length > 0 && Array.isArray(body) && colors) {
      bodyEl.innerHTML = ''
      body.map((element, index) => {
        body = document.createRange()
          .createContextualFragment(`<span class="legend-item">
        <label style="background-color: ${
          colors[index]
        }" class="circle"/></label>
        ${element}
        </span><br>`)
        bodyEl.appendChild(body)
      })
    } else {
      bodyEl.innerHTML = body
    }
  }

  /**
   * Adds the tooltip x to left, and y to top
   * @param x
   * @param y
   */
  translateTo({ x, y }) {
    if (!TweenLite) {
      TweenLite = require('gsap/TweenLite').default
    }

    if (this._tweenLite) {
      this._tweenLite.kill()
    }

    const to = { x, y }
    this._tweenLite = TweenLite.to([this], 0.5, to)
  }

  /**
   * Sets the tooltipElement's caret and the given coordinates
   */
  setPosition({ x, y }, placement = 'top') {
    if (this._tweenLite) {
      this._tweenLite.kill()
      this._tweenLite = null
    }
    this.placement = placement //must be set first
    this.x = x
    this.y = y
    this.scrollX = dom.getScrollX()
    this.scrollY = dom.getScrollY()
  }

  clear() {
    if (!this.getIsOpen()) {
      return
    }
    this.toggleTooltip()

    this.timeoutId = setTimeout(() => {
      this.setTitle()
      this.setBody()
    }, config.animationSpeed * 1000)
  }

  toggleTooltip(opacity) {
    if (!this.tooltipElement) return
    this.tooltipElement.style.opacity = opacity ? 1 : 0
  }

  destroy() {
    if (this._tweenLite) {
      this._tweenLite.kill()
    }
    if (!this.tooltipElement) {
      return
    }
    document.body.removeChild(this.tooltipElement)
    delete this.tooltipElement
  }
}

export default new CanvasTooltip()
