<template>
  <div class="shipment-card">
    <shipment-card
      v-for="(shipment, index) in shipments"
      :shipment="shipment"
      :key="index"/>
  </div>
</template>

<script>
import ShipmentCard from './ShipmentCard'

export default {
  name: 'ShipmentsCards',
  components: {
    ShipmentCard
  },
  props: {
    shipments: {
      type: Array,
      required: true
    }
  }
}
</script>
