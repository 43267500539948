<template>
  <tween-num 
    v-bind="$attrs" 
    :duration="duration" 
    easing="easeOutSine"/>
</template>
<script>
import TweenNum from 'vue-tween-number'

export default {
  name: 'TweenNumber',
  components: { TweenNum },
  inheritAttrs: false,
  data() {
    return {
      duration: 0
    }
  },
  mounted() {
    this.duration = 1000
  }
}
</script>
