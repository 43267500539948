<template>
  <div
    v-if="show"
    class="bol-tagging">
    <slot
      v-bind="{ isMainMaster }"
      name="text" />
    <nuxt-link
      v-if="link"
      :to="link"> {{ text }}
      <span
        :class="['bol-tag', { master: isMainMaster, house: !isMainMaster }]"
        :title="isMainMaster ? 'Master Bill of Lading': 'House Bill of Lading'">
        {{ isMainMaster ? 'M': 'H' }}
      </span>
    </nuxt-link>
  </div>
</template>

<script>
export default {
  name: 'BillTypeCode',
  props: {
    code: {
      type: String,
      default: 'master bill'
    },
    link: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    main: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    show() {
      return !(
        this.main === false && this.code.toLowerCase().search('master') >= 0
      )
    },
    isMainMaster() {
      if (this.main === true) {
        return this.code.toLowerCase().search('master') >= 0
      } else {
        return this.code.toLowerCase().search('house') >= 0
      }
    }
  }
}
</script>
