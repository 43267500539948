export default {
  getScrollY() {
    return (
      window.scrollY ||
      document.body.scrollTop ||
      document.documentElement.scrollTop ||
      window.pageYOffset
    )
  },
  getScrollX() {
    return (
      window.scrollX ||
      document.body.scrollLeft ||
      document.documentElement.scrollLeft ||
      window.pageXOffset
    )
  },
  getIsInBounds(target, x, y) {
    const { left, right, top, bottom } = target.getBoundingClientRect()
    return x > left && x < right && y > top && y < bottom
  }
}
