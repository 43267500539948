<template>
  <div>
    <bill-type-code
      :link="billOfLading.link"
      :text="billOfLading.text"
      :code="billOfLading.billTypeCode" />

    <bill-type-code
      :main="false"
      :link="billOfLading.masterBillOfLadingLink"
      :text="billOfLading.masterBillOfLading"
      :code="billOfLading.billTypeCode" >
      <template #text="{ isMainMaster }">
        {{ isMainMaster ? 'MASTER:&nbsp;': '' }}
      </template>
    </bill-type-code>
  </div>

</template>

<script>
import BillTypeCode from '~/components/BillTypeCode'

export default {
  name: 'BillOfLading',
  components: {
    BillTypeCode
  },
  props: {
    billOfLading: {
      type: Object,
      default: null
    }
  }
}
</script>
