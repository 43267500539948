<template>
  <div>
    <div
      v-if="editing && selectedFields.length === 0"
      class="clearfix drag-placeholder view-container view-tab-container">
      <div class="item-container">
        <div class="empty-box">
          Click to select fields on the right for your custom view.
        </div>
      </div>
    </div>
    <draggable
      v-else-if="editing"
      ref="dragarea"
      v-model="activeFields"
      class="clearfix view-container view-tab-container">
      <div
        v-for="(field, index) in activeFields"
        :key="index"
        class="list-box delete-item animated fadeIn">
        <i 
          class="removed-icon" 
          @click="removeField(field)"/>
        <span>{{ fields[field].displayName }}</span>
      </div>
    </draggable>
    <div 
      v-else 
      class="clearfix view-container view-tab-container">
      <div
        v-for="(field, index) in originalFields"
        :key="index"
        class="list-box animated fadeIn">
        <i class="checked-icon medium" /> <span>{{ fields[field].displayName }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
  components: {
    Draggable
  },

  props: {
    selectedFields: {
      type: Array,
      required: true
    },
    originalFields: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    editing: {
      type: Boolean,
      required: true
    }
  },

  computed: {
    activeFields: {
      get() {
        return this.selectedFields
      },
      set(fields) {
        this.$emit('change', fields.concat())
      }
    }
  },

  methods: {
    removeField(field) {
      this.$emit('remove', field)
    }
  }
}
</script>

<style src="~/assets/scss/components/data-viewer/view-fields-area.scss" lang="scss" />
