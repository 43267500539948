<template>
  <div class="popover-tooltip-notification small-content">
    <h3>Upgrade Account</h3>
    <div class="flex-container">
      <i class="database-upgrade-icon" />
      <p v-html="additionalDesc">
        <br >
        <a
          class="inline-normal"
          @click.prevent="toggleChatWidget">Contact</a>
        sales and support to upgrade your account.
      </p>
    </div>
  </div>
</template>
<script>
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'

export default {
  name: 'UpgradeAccountPopover',
  mixins: [openUpgradeModal],
  props: {
    additionalDesc: {
      type: String,
      required: true,
      default: ''
    },
    close: {
      type: Function,
      default: undefined
    }
  },
  mounted() {
    const self = this
    const upgradeElements = document.getElementsByClassName('upgrade-account')

    Array.from(upgradeElements).forEach(function(element) {
      element.addEventListener('click', self.showUpgrade)
    })
  },
  destroyed() {
    const self = this
    const upgradeElements = document.getElementsByClassName('upgrade-account')

    Array.from(upgradeElements).forEach(function(element) {
      element.removeEventListener('click', self.showUpgrade)
    })
  },
  methods: {
    showUpgrade() {
      this.openUpgradeModal()
      this.close()
    },
    toggleChatWidget() {
      this.$chat.toggle()
    }
  }
}
</script>
