<template>
  <div class="relative-pos">
    <fields-filter
      @filter-changed="applyFilter"
      @toggleMobileInput="toggleMobileInput">
      <shortcuts
        slot="mobile-content"
        :fields="fields"
        :active-fields="activeFields"
        :remaining-fields="remainingFields"
        :scrolling="scrolling"
        @addAll="addAll"
        @clearAll="clearAll"
        @modifyField="addOrRemoveField"/>
    </fields-filter>

    <batch-mover
      :active-fields="activeFields"
      :remaining-fields="remainingFields"
      @addAll="addAll"
      @clearAll="clearAll"/>

    <div class="available-fields-container web-only">
      <span
        v-if="isSelectableFieldsEmpty"
        class="no-results block-section align-center">
        No results found.
      </span>
      <button
        v-for="field in selectableFields"
        v-else
        :key="field"
        class="btn btn-default btn-small animated fadeIn"
        @click="addField(field)">
        {{ fields[field].displayName }}
      </button>
    </div>

    <div
      :class="{ open: mobileInput }"
      class="available-fields-container mobile-only"
      @scroll="handleScrolling">
      <span 
        v-if="isFilteredFieldsEmpty" 
        class="no-results">
        No results found.
      </span>
      <button
        v-for="field in filteredFields"
        v-else
        :class="{ active: isActiveField(field) }"
        :key="field"
        class="btn btn-default btn-small animated fadeIn"
        @click="addOrRemoveField(field)">
        {{ fields[field].displayName }}
      </button>
    </div>
  </div>
</template>

<script>
import BatchMover from './BatchMover'
import FieldsFilter from './FieldsFilter'
import Shortcuts from './Shortcuts'

export default {
  components: {
    BatchMover,
    FieldsFilter,
    Shortcuts
  },
  props: {
    fields: {
      type: Object,
      required: true
    },
    activeFields: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      filter: '',
      mobileInput: false,
      scrolling: false
    }
  },

  computed: {
    isFilteredFieldsEmpty() {
      return this.filteredFields.length === 0
    },

    isSelectableFieldsEmpty() {
      return this.selectableFields.length === 0
    },

    selectableFields() {
      return this.filteredFields.filter(i => !this.isActiveField(i))
    },

    remainingFields() {
      return Object.keys(this.fields).filter(i => !this.isActiveField(i))
    },

    filteredFields() {
      const fields = Object.keys(this.fields).filter(
        i =>
          this.fields[i].displayName
            .toLowerCase()
            .indexOf(this.filter.toLowerCase()) > -1
      )
      return this.$store.getters['views/sortFields'](fields)
    }
  },

  methods: {
    handleScrolling(e) {
      this.scrolling = e.target.scrollTop > 0
    },
    toggleMobileInput(val) {
      this.mobileInput = val
    },
    isActiveField(field) {
      return this.activeFields.indexOf(field) > -1
    },

    filterBySubstring(stringArray, substring) {
      return stringArray.filter(str =>
        str.toLowerCase().indexOf(substring.toLowerCase())
      )
    },

    applyFilter(filter) {
      this.filter = filter
      this.$emit('filter-changed', filter)
    },

    clearAll() {
      this.$emit('clear-all')
    },

    addAll() {
      this.$emit('add-all')
    },

    addOrRemoveField(field) {
      if (this.isActiveField(field)) {
        this.removeField(field)
      } else {
        this.addField(field)
      }
    },

    addField(field) {
      this.$emit('add-field', field)
    },

    removeField(field) {
      this.$emit('remove-field', field)
    }
  }
}
</script>

<style src="~/assets/scss/components/data-viewer/remaining-fields.scss" lang="scss" />
