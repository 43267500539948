<template>
  <div class="data-viewer-header exp-1">

    <div class="data-viewer-header__left">
      <slot name="headerLeft"/>
    </div>

    <div class="data-viewer-header__right exp-1">
      <slot
        v-bind="{ viewType }"
        name="headerRight"/>

      <!-- <views-menu
        v-if="showCustomView && viewType === 'table'"
        :class="{ 'active': FirstLoginTour.currentStep === 7 && FirstLoginTour.show }"
        class="first-login-tour-7"
        @view-modal="openCustomView" /> -->

      <el-tooltip
        v-if="showCardsIcon"
        :content="`Show ${viewType === 'table' ? 'Cards' : 'Table'}`">
        <i
          :class="`${viewType === 'table' ? 'card' : 'table'}-view-icon`"
          @click="$emit('view-type', viewType === 'table' ? 'card' : 'table')"/>
      </el-tooltip>

      <el-tooltip
        v-if="showFullScreenIcon"
        :content="showFullScreen ? 'Exit Fullscreen' : 'Fullscreen'">
        <i
          :class="showFullScreen ? 'minimize-window-icon' : 'full-window-icon'"
          @click="$emit('full-screen', !showFullScreen)"/>
      </el-tooltip>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import ViewsMenu from '~/components/data-viewers/plugins/ViewsMenu/ViewsMenu'

export default {
  name: 'DataViewerHeader',
  components: {
    ViewsMenu
  },
  props: {
    showCardsIcon: {
      type: Boolean,
      default: false
    },
    showFullScreen: {
      type: Boolean,
      default: false
    },
    showFullScreenIcon: {
      type: Boolean,
      default: true
    },
    showCustomView: {
      type: Boolean,
      default: false
    },
    viewType: {
      type: String,
      default: 'table',
      validator(value) {
        switch (value) {
          case 'table':
          case 'card':
            return true
          default:
            return false
        }
      }
    }
  },
  computed: {
    ...mapState('tours', ['FirstLoginTour'])
  },
  methods: {
    openCustomView(open) {
      this.$emit('view-modal', open)
    }
  }
}
</script>
<style src="~/assets/scss/components/data-viewer/data-viewer-header.scss" lang="scss"/>
