<template>
  <transition
    name="zoom-in-top"
    @after-leave="close">
    <div
      v-show="visible"
      tabindex="-1"
      class="date-range">
      <div
        v-if="value"
        class="date-range__content">
        <div class="flex-container">
          <div class="calendar-container">
            <date-inputs
              ref="dateInputs"
              :dates="value"
              :min-date="minDate"
              :max-date="maxDate"
              :disabled-date="disabledDate"
              @error="onError"
              @pick="onInput"/>

            <date-tables
              :date="date"
              :min-date="minDate"
              :max-date="maxDate"
              :disabled-date="disabledDate"
              :range-state="rangeState"
              :close="close"
              @date="onDate"
              @range="onRange"
              @pick="onPick"/>
          </div>
          <slot
            name="sidebar"
            class="el-picker-panel__sidebar"/>
          <div class="pre-selection-picker">
            <ul>
              <el-popover
                v-for="(shortcut, key) in shortcuts"
                :key="key"
                :disabled="!isDisabled(shortcut)"
                placement="bottom"
                trigger="hover">
                <upgrade-account-popover
                  :close="close"
                  :additional-desc="`<a href='#' class='upgrade-account inline-normal'>Upgrade</a> your account to view data before ${$_date.format(shortcut.subscriptionDateRange.from, 'yyyy-MM-dd')}.`"/>

                <li
                  slot="reference"
                  :class="{
                    'btn-disable': isDisabled(shortcut),
                    active: isSelected(shortcut)
                }">
                  <button
                    :class="{ 'btn-disable': isDisabled(shortcut) }"
                    type="button"
                    class="el-picker-panel__shortcut"
                    @click="handleShortcutClick(shortcut, isDisabled(shortcut))">
                    {{ shortcut.text }}
                  </button>
                </li>
              </el-popover>
            </ul>
          </div>
        </div>

        <div class="date-range__footer flex-container justify-right">
          <button
            class="btn btn-text secondary"
            @click="$emit('close')">
            Cancel
          </button>
          <button
            :disabled="disabledSubmit"
            class="btn btn-default btn-primary"
            @click="handleConfirm">Apply</button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script type="text/babel">
import { toDate } from '../util/index'

import Locale from 'element-ui/lib/mixins/locale'
import DateInputs from './DateInputs/DateInputs'
import DateTables from './DateTables/DateTables'
import UpgradeAccountPopover from '~/components/common/UpgradeAccountPopover'
import { nextMonth, equalDate } from '../util/index'

const calcDefaultValue = defaultValue => {
  if (Array.isArray(defaultValue)) {
    return defaultValue[0] ? new Date(defaultValue[0]) : new Date()
  } else {
    return new Date(defaultValue)
  }
}

export default {
  name: 'DateRange',
  components: { DateInputs, DateTables, UpgradeAccountPopover },
  mixins: [Locale],
  data() {
    return {
      minPickerWidth: 0,
      maxPickerWidth: 0,
      date: this.$options.defaultValue
        ? calcDefaultValue(this.$options.defaultValue)
        : new Date(),
      minDate: null,
      maxDate: null,
      rangeState: {
        endDate: null,
        selecting: false,
        row: null,
        column: null
      },
      value: null,
      visible: false,
      disabledDate: null,
      disabledSubmit: false
    }
  },
  watch: {
    visible(visible) {
      if (!visible) {
        return
      }
      // Delay to next tick because dateInputs is not yet visible, v-if
      this.$nextTick(() => {
        this.$refs.dateInputs.focus()
      })
    },
    value(newVal) {
      if (!newVal) {
        this.minDate = null
        this.maxDate = null
      } else if (Array.isArray(newVal)) {
        this.resetView(newVal)
      }
    }
  },
  methods: {
    onError(value) {
      this.disabledSubmit = value
      this.$emit('error', value)
    },
    handleShortcutClick(shortcut, isDisabled) {
      if (!isDisabled && shortcut.onClick) {
        shortcut.onClick(this)
        this.minDate = toDate(shortcut.from)
        this.maxDate = toDate(shortcut.to)
        if (this.maxDate) this.date = new Date(this.maxDate)
      }
    },
    resetView(newVal = this.value) {
      this.minDate = newVal[0] ? toDate(newVal[0]) : null
      this.maxDate = newVal[1] ? toDate(newVal[1]) : null
      if (this.maxDate) this.date = new Date(this.maxDate)
    },
    onRange(val) {
      this.minDate = val.minDate
      this.maxDate = val.maxDate
      this.rangeState = val.rangeState
    },
    onInput(val) {
      if (this.maxDate === val.maxDate && this.minDate === val.minDate) {
        this.$emit('pick', [this.minDate, this.maxDate])
        return
      }

      let changeStart = !equalDate(this.minDate, val.minDate)
      let changeEnd = !equalDate(this.maxDate, val.maxDate)

      this.maxDate = val.maxDate
      this.minDate = val.minDate

      let view = new Date(this.date).getTime()
      let start = new Date(this.minDate).getTime()
      let end = new Date(this.maxDate).getTime()

      if (view < start || (changeStart && view > start))
        view = nextMonth(new Date(start))
      if (view > end || (changeEnd && view < end)) view = end

      this.$emit('pick', [val.minDate, val.maxDate])
      // workaround for https://github.com/ElemeFE/element/issues/7539, should remove this block when we don't have to care about Chromium 55 - 57
      setTimeout(() => {
        this.maxDate = val.maxDate
        this.minDate = val.minDate
        this.date = new Date(view)
      }, 10)
    },
    onPick(val) {
      if (this.maxDate === val.maxDate && this.minDate === val.minDate) {
        this.$emit('pick', [this.minDate, this.maxDate])
        return
      }

      this.maxDate = val.maxDate
      this.minDate = val.minDate

      this.$emit('pick', [val.minDate, val.maxDate])
      // workaround for https://github.com/ElemeFE/element/issues/7539, should remove this block when we don't have to care about Chromium 55 - 57
      setTimeout(() => {
        this.maxDate = val.maxDate
        this.minDate = val.minDate
      }, 10)
    },
    onDate(date) {
      this.date = date
    },

    close() {
      this.$emit('dodestroy')
      this.$refs.dateInputs.clearErrors()
    },

    handleConfirm() {
      if (this.$refs.dateInputs.startError || this.$refs.dateInputs.endError) {
        return
      }
      this.$emit('pick', [this.minDate, this.maxDate], true)
    },
    isDisabled({ from, to, subscriptionDateRange, minDate, maxDate }) {
      return (
        from < minDate ||
        to > maxDate ||
        from < subscriptionDateRange.from ||
        to > subscriptionDateRange.to
      )
    },
    isSelected({ from, to }) {
      return (
        this.getIsSameYearMonthDate(+from, this.minDate) &&
        this.getIsSameYearMonthDate(+to, this.maxDate)
      )
    },
    getIsSameYearMonthDate(a, b) {
      const aDate = new Date(a)
      const bDate = new Date(b)
      return (
        aDate.getFullYear() === bDate.getFullYear() &&
        aDate.getMonth() === bDate.getMonth() &&
        aDate.getDate() === bDate.getDate()
      )
    }
  }
}
</script>
<style src="../styles/date-range.scss" lang="scss" />
