<template>
  <div
    v-if="active"
    class="temp-field animated fadeIn">
    <div
      v-click-outside="close"
      class="flex-container">
      <el-tooltip
        :value="isError"
        :manual="true"
        placement="top"
        popper-class="is-light newview-error">
        <template slot="content">
          <div class="popover-tooltip-notification small-content">
            <h3>{{ errorTitle }}</h3>
            <div class="flex-container">
              <i class="error-icon" />
              <p>{{ errorMessage }}</p>
            </div>
          </div>
        </template>
        <input
          ref="newViewInput"
          v-model="name"
          :class="{ error: isError }"
          type="text"
          class="new_view_textbox"
          placeholder="Enter Name"
          @keyup.enter="create">
      </el-tooltip>
      <button
        v-if="name.length"
        class="btn btn-text secondary cancel-new visible"
        @click="clear">
        <!-- <span class="delete-icon" /> -->
      </button>
      <button
        class="btn-default btn-primary btn-small create-new"
        @click="create">Create</button>
      <button
        class="btn-text secondary close-new-field"
        @click="close"><i class="el-icon-close "/></button>
    </div>
  </div>
  <button
    v-else
    :class="{ disabled: pending || editing }"
    class="btn btn-default btn-primary"
    @click="open">
    Create New
  </button>
</template>

<script>
import { startCase } from 'lodash'

export default {
  props: {
    views: {
      type: Array,
      required: true
    },
    pending: {
      type: Boolean,
      required: true
    },
    active: {
      type: Boolean,
      required: true
    },
    editing: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      name: '',
      isError: false,
      errorTitle: '',
      errorMessage: ''
    }
  },

  computed: {
    nameIsEmpty() {
      return this.name.trim().length === 0 ? 'disabled' : 'enabled'
    }
  },

  methods: {
    open() {
      this.$emit('open')
      this.$nextTick(() => {
        this.$refs.newViewInput.focus()
      })
    },

    close() {
      this.clearName()
      this.$emit('cancel')
    },

    create() {
      const newViewName = startCase(this.name.toLowerCase())
      this.isError = false
      this.errorTitle = ''
      this.errorMessage = ''

      if (this.views.find(view => view.name === newViewName) !== undefined) {
        this.isError = true
        this.errorTitle = 'Error'
        this.errorMessage = `${this.name} already exists`
      }

      if (this.name.trim() === '') {
        this.isError = true
        this.errorTitle = 'Error'
        this.errorMessage = 'Custom name cannot be empty'
      }

      if (this.isError && this.errorMessage) {
        setTimeout(() => {
          this.isError = false
        }, 4000)
        return
      }

      this.$emit('create', this.name)
      this.clearName()
    },

    clear() {
      this.clearName()
      this.$nextTick(() => {
        this.$refs.newViewInput.focus()
      })
    },

    clearName() {
      this.name = ''
      this.isError = false
      this.errorTitle = ''
      this.errorMessage = ''
    }
  }
}
</script>
<style src="~/assets/scss/components/data-viewer/new-views-prompt.scss" lang="scss" />
