<template>
  <p
    v-if="data.tooltip"
    :class="data.class">
    <BillOfLading
      v-if="isMasterAndHouse && data.billOfLadingObject"
      :bill-of-lading="data.billOfLadingObject" />
    <nuxt-link
      v-else-if="data.link"
      :to="data.link"
      v-html="data.text"/>
    <span
      v-else
      v-html="visibleString.string" />
    <span
      v-if="visibleString.hasOverflow && !data.link"
      :class="{ ellipsis_collapse: showFullText }"
      class="ellipsis"
      @click="showFullString()">
      ...
    </span>
    <el-tooltip
      :content="data.tooltip"
      popper-class="data-viewer-tooltip light">
      <i class="el-icon-question" />
    </el-tooltip>
  </p>
  <BillOfLading
    v-else-if="isMasterAndHouse && data.billOfLadingObject"
    :bill-of-lading="data.billOfLadingObject" />
  <nuxt-link
    v-else-if="data.link"
    :to="data.link"
    v-html="data.text"/>
  <p
    v-else-if="!visibleString.hasOverflow && !data.link"
    v-html="visibleString.string" />
  <p
    v-else
    :class="data.class" >
    <span v-html="visibleString.string" />
    <span
      v-if="visibleString.hasOverflow && !data.link"
      :class="{ ellipsis_collapse: showFullText }"
      class="ellipsis"
      @click="showFullString()">
      ...
    </span>
  </p>
</template>

<script>
import { statesKeys, countries } from '~/utils/address'
import BillOfLading from './DataTableCells/BillOfLading.vue'
import { convertToTwoDigitDecimal } from '~/utils/number'
export default {
  name: 'DataTableCell',
  components: {
    BillOfLading
  },
  props: {
    header: {
      type: Object,
      required: true
    },
    data: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    hasCheckboxes: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      visibleString: {
        string: this.data.text,
        hasOverflow: false
      },
      showFullText: false,
      width: 168
    }
  },
  computed: {
    isMasterAndHouse() {
      if (this.data.billOfLadingObject) {
        const isHouse =
          this.data.billOfLadingObject.billTypeCode
            .toLowerCase()
            .search('house') >= 0
        const isMaster =
          this.data.billOfLadingObject.billTypeCode
            .toLowerCase()
            .search('master') >= 0

        return isHouse || isMaster
      }

      return false
    }
  },
  watch: {
    data() {
      this.initializeData()
    }
  },
  mounted() {
    this.$bus.$on('resizedColumn', this.checkOverflow)
  },
  destroyed() {
    this.$bus.$off('resizedColumn', this.checkOverflow)
  },
  methods: {
    initializeData() {
      this.visibleString = {
        string: this.data.text,
        hasOverflow: false
      }
      this.showFullText = false
    },
    formatText(text, headerId) {
      if (headerId.toLowerCase().includes('date')) {
        text = this.$date.formatUtc(text, 'yyyy-MM-dd')
      } else if (['ConsigneeState', 'CarrierState'].includes(headerId)) {
        text = statesKeys[text] || text
      } else if (['VesselCountry'].includes(headerId)) {
        const id = countries.findIndex(country => country.code === text)
        text = countries[id] ? countries[id].name : text
      } else if (
        ['CalculatedTEU', 'NumberOfContainers', 'containersCount'].includes(
          headerId
        )
      ) {
        if (text !== 0) {
          text = convertToTwoDigitDecimal(text)
        }
      } else if (typeof text === 'number') {
        text = this.$numeral(text).format('0,0')
      } else {
        while (Array.isArray(text)) {
          text = text.join(', ')
        }
      }

      return text || 'n/a'
    },
    getVisibleString(string, width) {
      if (!string) {
        return
      }

      const numOfLines = 3
      const spaceWidth = 2
      string = string.toLowerCase()
      let words = string.split(' '),
        wordWidth,
        lineWidth = 0,
        tempString = '',
        i = 0,
        j = 0
      for (i; i < words.length && j < numOfLines; i++) {
        wordWidth = this.getTextWidth(words[i], 'normal 12pt helvetica')
        if (lineWidth + wordWidth <= width) {
          lineWidth += wordWidth + spaceWidth
          tempString += words[i] + ' '
        } else {
          j++
          if (j < numOfLines) {
            lineWidth = wordWidth
            tempString += words[i] + ' '
          }
        }
      }

      let finalString = this.showFullText ? string : tempString
      let hasOverflow = j === 3 ? i - 2 < words.length : false
      this.visibleString = { string: finalString, hasOverflow }
    },
    getTextWidth(text, font) {
      let canvas = document.createElement('canvas')
      let context = canvas.getContext('2d')
      context.font = font
      let metrics = context.measureText(text)

      return Math.floor(metrics.width)
    },
    checkOverflow(width, index, tableName) {
      // columnResizer returns the index of the column which left side moved
      if (
        this.index === index - !!this.hasCheckboxes &&
        this.$parent.$refs.table.id === tableName
      ) {
        let string = this.formatText(this.data.text, this.header.id)
        let widthNum = parseInt(width) - 30
        this.width = widthNum
        this.visibleString.string = this.getVisibleString(string, widthNum)
      }
    },
    showFullString() {
      let string = this.formatText(this.data.text, this.header.id)

      if (this.showFullText) {
        this.showFullText = false
        this.visibleString.string = this.getVisibleString(string, this.width)
        return
      }
      this.showFullText = true
      this.visibleString.string = string
    }
  }
}
</script>

<style lang="scss">
strong {
  font-weight: bold;
}
</style>
