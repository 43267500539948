<template>
  <div
    v-loading="isLoading"
    v-show="isLoading || chartData.data.length > 0"
    class="pie-item">
    <h2>Top {{ portLabel }} Ports {{ dateRangeText }}</h2>

    <pie-chart
      :data="chartData.data"
      :legend="chartData.legend"
      pie-class="overview-pie-chart"/>
  </div>
</template>

<script>
import { sum, sumBy } from 'lodash'
import { mapState, mapGetters } from 'vuex'
import PieChart from '~/components/charts/PieChart'

export default {
  name: 'TopPortsChart',
  components: { PieChart },
  props: {
    portType: {
      type: String,
      required: true,
      validator(value) {
        switch (value) {
          case 'origins':
          case 'destinations':
            return true
          default:
            return false
        }
      }
    }
  },
  data() {
    return {
      colors: []
    }
  },
  computed: {
    ...mapState('company', ['dateRange']),
    ...mapState('company/overview', ['topPorts']),
    ...mapGetters('company/overview', ['dateRangeText']),

    portLabel() {
      switch (this.portType) {
        case 'origins':
          return 'Foreign'
        case 'destinations':
          return 'U.S.'
      }
    },
    chartData() {
      const topPorts = this.topPorts[this.portType]
      const data = []
      const legend = []
      const minLength = topPorts.length > 6 ? 5 : 6
      const length = Math.min(topPorts.length, minLength)

      for (let i = 0; i < length; i++) {
        data.push(topPorts[i].count)
        legend.push({ label: topPorts[i].name })
      }

      if (data.length === 5 && topPorts.length > 6) {
        const otherCount = sumBy(topPorts, 'count') - sum(data)
        data.push(otherCount)
        legend.push({ label: `OTHER ${this.portLabel.toUpperCase()} PORTS` })
      }

      return {
        legend,
        data
      }
    },

    isLoading() {
      return this.$actions.pending('company/overview/fetchTopPorts')
    }
  }
}
</script>
