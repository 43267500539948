<template>
  <div>
    <span
      v-if="input && filter.length < 1"
      class="btn btn-text secondary search-cancel-btn"
      @click="closeInput">Cancel</span>
   
    <div class="tm-seach-input">
      <span
        v-if="filter"
        class="removed-icon small"
        @click="clear"/>
      <i 
        class="fa fa-search" 
        @click="openInput"/>
      <input
        ref="filterer"
        v-model="filter"
        :class="{ open: input }"
        class="field-search"
        placeholder="Search field"
        @focus="openInput"
        @blur="closeInput">
      <slot name="mobile-content" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filter: '',
      input: false
    }
  },

  watch: {
    filter(newValue) {
      this.$emit('filter-changed', newValue)
      this.$bus.$emit('filterHasChanged')
    }
  },

  mounted() {
    this.$bus.$on('focusOnFilterInput', this.selectFilterInput)
  },

  destroyed() {
    this.$bus.$off('focusOnFilterInput', this.selectFilterInput)
  },
  methods: {
    clear() {
      this.filter = ''
      this.selectFilterInput()
    },

    selectFilterInput() {
      this.$refs.filterer.focus()
    },

    openInput() {
      this.$emit('toggleMobileInput', true)
      this.input = true
      this.selectFilterInput()
    },

    closeInput() {
      this.$emit('toggleMobileInput', false)
      this.input = false
    }
  }
}
</script>
