import { merge } from 'lodash'
import config from '~/config/canvasTooltip'

const defaultContfig = {
  id: 'canvas-tooltip',
  class: 'canvas-tooltip',
  caretSize: 8,
  animationSpeed: 0.2
}

export default merge({}, defaultContfig, config)
