<template>
  <div class="section-panel nav-panel-section">
    <section>
      <h4
        :class="{ active: foundInPresets, open: collapsePresets }"
        @click="collapsePresets = !collapsePresets">
        Preset Views <i class="el-collapse-item__arrow el-icon-arrow-down" />
      </h4>
      <ul
        class="nav nav-tabs view-tab-list"
        role="tablist">
        <template v-if="collapsePresets">
          <li
            v-for="(view, i) in presetViews"
            :key="i"
            :class="{ active: isActive(view) }"
            class="view-tab default"
            @click="handleMoveActive(view)">
            <a>{{ view.name }}
            <span v-if="!isSaved(view)">(unsaved)</span></a>
            <i
              v-if="$device.isMobile"
              class="fa fa-angle-right"
              @click="select(view)"/>
          </li>
        </template>
      </ul>
    </section>
    <section>
      <h4
        :class="{
          active: foundInCustom,
          open: collapseCustoms,
          'removed-margin-top': !collapsePresets,
          empty: !customViews.length
        }"
        @click="toggleCollapseCustoms">
        Custom Views
        <i
          v-if="customViews.length"
          class="el-collapse-item__arrow el-icon-arrow-down" />
      </h4>
      <ul class="nav nav-tabs view-tab-list">
        <template v-if="collapseCustoms">
          <li
            v-for="(view, i) in customViews"
            :key="view + i"
            :class="{ active: isActive(view) }"
            class="view-tab default"
            @click="handleMoveActive(view)">
            <a>{{ view.name }}
            <span v-if="!isSaved(view)">(unsaved)</span></a>
            <i
              v-if="$device.isMobile"
              class="fa fa-angle-right"
              @click="select(view)"/>
          </li>
        </template>
      </ul>
      <div
        v-if="!customViews.length"
        class="empty-state">
        <i class="empty-icon large-icon"/>
        <h2>No Custom Views</h2>
        <p>You have no custom views yet. <br>Click on the "NEW" button below to create your custom view.</p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    views: {
      type: Array,
      required: true
    },
    selectedView: {
      type: Object,
      required: true
    },
    newView: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      collapsePresets: true,
      collapseCustoms: true
    }
  },

  computed: {
    allViews() {
      return this.newView.name.length
        ? [...this.views, this.newView]
        : this.views
    },
    presetViews() {
      return this.allViews.filter(view => view.readonly)
    },
    customViews() {
      return this.allViews.filter(view => !view.readonly)
    },
    foundInPresets() {
      return this.presetViews.findIndex(view => view === this.selectedView) > -1
    },
    foundInCustom() {
      return this.customViews.findIndex(view => view === this.selectedView) > -1
    }
  },

  methods: {
    select(view) {
      this.$emit('select', view)
    },

    isSaved(view) {
      return this.views.find(v => v === view)
    },

    moveActive(view) {
      if (view === this.selectedView) {
        return
      }
      this.$emit('change', view)
    },

    moveActiveMobile(view) {
      this.$emit('change-mobile', view)
    },

    isActive(view) {
      return this.selectedView === view
    },

    handleMoveActive(view) {
      if (this.$device.isMobile) {
        this.moveActiveMobile(view)
        return
      }
      this.moveActive(view)
    },

    toggleCollapseCustoms() {
      if (!this.customViews.length) {
        return
      }
      this.collapseCustoms = !this.collapseCustoms
    }
  }
}
</script>
