<template>
  <div
    v-loading="loading"
    class="empty-state">
    <empty-state-notification :title="'No data available on the selected dates!'"/>
    <p>Please select another date range or click <button
      class="btn-default btn-text"
      @click="changeDateRange(subscriptionDateRange)">here </button> to view <br>all shipment data for this company.</p>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import EmptyStateNotification from '~/components/common/EmptyStateNotification'
export default {
  name: 'CompanyPlaceholder',
  components: {
    EmptyStateNotification
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('userSubscriptions', ['subscriptionDateRange'])
  },
  methods: {
    ...mapActions('company', ['changeDateRange'])
  }
}
</script>
