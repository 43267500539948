import canvasTooltip from '~/utils/canvasTooltip'
import { convertToTwoDigitDecimal } from '~/utils/number'
import dom from '~/utils/dom'
import { findIndex, flatten } from 'lodash'

/**
 * Pass in functions to options.tooltips[title/body] for custom functions.
 * Create method tooltipsCallback for custom tooltips.
 */
export default {
  mounted() {
    this.tooltip = canvasTooltip
    this.tooltip.initialize()
  },
  computed: {
    tooltips() {
      const self = this
      const getTooltip = function(tooltipModel) {
        if (tooltipModel.opacity === 0 || !tooltipModel.body) {
          return this.tooltip.clear()
        }

        const { title, body, placement, colors } = (() => {
          const { datasetIndex, index } = tooltipModel.dataPoints[0]
          const title =
            this.chartData.datasets[datasetIndex].data[index].x || // used by line chart
            this.labels[index] // used by all others
          const dataKey = tooltipModel.dataPoints.map(
            dataPoint => this.chartData.datasets[dataPoint.datasetIndex].dataKey
          )
          const body = tooltipModel.body.map(item => item.lines)
          const colors = tooltipModel.labelColors
            .filter(
              (item, index) =>
                tooltipModel.labelColors.length > 3 ? index % 2 === 0 : item
            )
            .map(item => item.backgroundColor)
          const args = {
            title,
            body:
              body.length > 3
                ? body.filter((item, index) => index % 2 === 0)
                : body,
            index,
            dataKey,
            colors
          }

          const tooltipCallback =
            this.tooltipCallback || this.options.tooltipCallback

          if (typeof tooltipCallback === 'function') {
            return tooltipCallback(args)
          }

          return args
        })()
        this.setTooltip({
          title,
          body,
          placement,
          x: tooltipModel.caretX,
          y: tooltipModel.caretY,
          colors
        })
      }

      return {
        mode: 'point',
        enabled: self.chartId === 'line-chart' ? true : false,
        custom: self.chartId === 'line-chart' ? null : getTooltip.bind(this),
        callbacks:
          self.chartId === 'line-chart'
            ? {
                label: function(tooltipItem, data) {
                  const { index, datasetIndex } = tooltipItem
                  const { data: _data } = data.datasets[datasetIndex]
                  const { incomplete } = self.getCompletedIncompleteIndices()
                  const allIncomplete = flatten(incomplete)
                  const incompleteDataIndex = findIndex(self.labels, label =>
                    label.includes(_data[index].x)
                  )
                  const minIndex = Math.min.apply(null, allIncomplete)
                  const maxIndex = Math.max.apply(null, allIncomplete)

                  if (data.datasets[datasetIndex].borderDash) {
                    if (incomplete.length > 1) {
                      if (incompleteDataIndex === minIndex) {
                        return self.setTooltipBody(tooltipItem, data)
                      } else if (incompleteDataIndex + 1 === maxIndex) {
                        return self.setTooltipBody(tooltipItem, data)
                      }
                    } else {
                      /** do not add to tooltips if data in incompleteDataIndex is actually complete */
                      const isComplete = self.$date.getIsCompleteInterval(
                        ...data.datasets[datasetIndex].data[index].x
                          .split(' - ')
                          .map(date => parseInt(date)),
                        self.interval
                      )
                      if (isComplete) return

                      if (incompleteDataIndex === 0) {
                        return self.setTooltipBody(tooltipItem, data)
                      } else if (incompleteDataIndex + 1 === maxIndex) {
                        return self.setTooltipBody(tooltipItem, data)
                      }
                    }
                  } else {
                    return self.setTooltipBody(tooltipItem, data)
                  }
                },
                title: function(tooltipItem) {
                  const format = 'yyyy-MM-dd'
                  const { index, datasetIndex } = tooltipItem[0]
                  let title =
                    self.chartData.datasets[datasetIndex].data[index].x ||
                    self.labels[index]

                  title = title
                    .split('-')
                    .map(timestamp => self.$date.formatUtc(+timestamp, format))
                    .join(' - ')
                  if (self.interval === 'days') {
                    title = title.split(' - ')[0]
                  }
                  return title
                }
              }
            : {},
        backgroundColor: 'white',
        titleFontColor: 'black',
        bodyFontColor: 'black',
        borderColor: 'black',
        borderWidth: 0.5,
        yPadding: 12,
        xPadding: 12,
        cornerRadius: 6,
        titleFontFamily: "'Overpass', serif",
        bodyFontFamily: "'Overpass', serif",
        opacity: 0
      }
    }
  },
  methods: {
    setTooltip({ title, body, x, y, placement, colors }) {
      const canvas = this.$data._chart.canvas.getBoundingClientRect()
      const position = {
        x: canvas.left + dom.getScrollX() + x,
        y: canvas.top + dom.getScrollY() + y
      }
      this.tooltip.setTooltip({ title, body, position, placement, colors })
    },
    setTooltipBody(tooltipItem, data) {
      const self = this
      const tooltipTextIndex = data.datasets.map(data =>
        self.tooltipText.findIndex(text => text.key === data.dataKey)
      )
      const name = data.datasets[tooltipItem.datasetIndex].name

      let body = name
        ? tooltipTextIndex.map((value, index) => {
            if (value === -1) {
              return `${name} - ${convertToTwoDigitDecimal(
                tooltipItem.yLabel
              )} ${self.tooltipText}`
            }
            if (index === tooltipItem.datasetIndex) {
              return `${name} - ${convertToTwoDigitDecimal(
                tooltipItem.yLabel
              )} ${self.tooltipText[value].text}`
            }
          })
        : tooltipTextIndex.map((value, index) => {
            if (value === -1) {
              return `${convertToTwoDigitDecimal(tooltipItem.yLabel)} ${
                self.tooltipText
              }`
            }
            if (index === tooltipItem.datasetIndex) {
              return `${convertToTwoDigitDecimal(tooltipItem.yLabel)} ${
                self.tooltipText[value].text
              }`
            }
          })
      body = body.filter(Boolean)

      return body[0]
    },
    onAxisHover(event, chartElements) {
      if (chartElements.length > 0) {
        return
      }
      const { _chart: chart } = this.$data
      // Find x-axis.label intersecting element
      const [element] = chart.constructor.Interaction.modes.x(chart, event, {
        intersect: false
      })

      if (!element) {
        return this.tooltip.clear()
      }

      // Check if mouse event is within x-axis bounds (not inside graph)
      const { _xScale: xAxis } = element
      const eventY = event.offsetY || event.layerY
      if (xAxis.top > eventY || eventY > xAxis.bottom) {
        return this.tooltip.clear()
      }

      if (this.tooltip.getIsOpen()) {
        return
      }

      const tooltipModel = this.chartData.datasets[element._datasetIndex].data[
        element._index
      ]
      this.setTooltip({
        title: element._model.label,
        body: this.getTooltipBody(tooltipModel),
        x: element._model.x,
        y: element._xScale.bottom,
        placement: 'bottom'
      })
    }
  }
}
