<template>
  <div class="add-all-clear-all">
    <button
      :class="{
        disabled: !activeFields.length,
        delayed: lastHover === 'addAll'
      }"
      class="fa fa-long-arrow-right"
      @mouseover="addDelay('addAll')"
      @click="clearFields"/>
    <button
      :class="{
        disabled: !remainingFields.length,
        delayed: lastHover === 'clearFields'
      }"
      class="fa fa-long-arrow-left"
      @mouseover="addDelay('clearFields')"
      @click="addAllFields"/>
  </div>
</template>

<script>
export default {
  props: {
    activeFields: {
      type: Array,
      required: true
    },
    remainingFields: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      lastHover: ''
    }
  },

  methods: {
    addDelay(buttonName) {
      this.lastHover = buttonName === 'addAll' ? 'clearFields' : 'addAll'
    },

    clearFields() {
      this.$emit('clearAll')
    },

    addAllFields() {
      this.$emit('addAll')
    }
  }
}
</script>
