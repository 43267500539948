<template>
  <div class="items-selected-box bottom-shadow">
    <h3 v-if="!scrolling">
      Tap to <strong>select/deselect</strong> a field below or
      <strong>search</strong> for a specific field above.
    </h3>
    <span
      :class="{ selected: !remainingFields.length }"
      class="radio-button-default select-all"
      @click="addAll">
    <span class="default-radio-btn" /> Select All</span>
    <span
      :class="{ selected: !activeFields.length }"
      class="radio-button-default clear-all"
      @click="clearAll">
      Clear
    </span>
    <span 
      class="items-selected-count" 
      @click="toggle">
      <span>
        <strong>{{ activeFields.length }} item(s) Selected</strong>
      </span>
      <span :class="isFieldsListOpen" />
    </span>
    <div
      v-if="fieldsListOpen"
      class="selected-list-preview-box bottom-shadow visible">
      <div class="inner-preview-box">
        <span
          v-if="isActiveFieldsEmpty"
          class="no-results block-section align-center">No active fields.</span>
        <div
          v-for="field in activeFields"
          v-else
          :key="field"
          class="list-box animated fadeIn delete-item">
          <i 
            class="checked-icon" 
            @click="changeField(field)"/>
          <span>{{ fields[field].displayName }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeFields: {
      type: Array,
      required: true
    },
    remainingFields: {
      type: Array,
      required: true
    },
    fields: {
      type: Object,
      required: true
    },
    scrolling: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      fieldsListOpen: false
    }
  },

  computed: {
    isFieldsListOpen() {
      return this.fieldsListOpen ? 'caret open' : 'caret'
    },

    isActiveFieldsEmpty() {
      return this.activeFields.length === 0
    }
  },

  mounted() {
    this.$bus.$on('filterHasChanged', this.closeFieldList)
  },

  destroyed() {
    this.$bus.$off('filterHasChanged', this.closeFieldList)
  },

  methods: {
    toggle() {
      this.fieldsListOpen = !this.fieldsListOpen
    },

    clearAll() {
      this.$emit('clearAll')
    },

    addAll() {
      this.$emit('addAll')
    },

    changeField(field) {
      this.$emit('modifyField', field)
    },

    closeFieldList() {
      this.fieldsListOpen = false
    }
  }
}
</script>
