<template>
  <div class="card-table">
    <div class="card-headbar">
      <i class="fa fa-calendar-check-o"/>
      <span
        class="arrival-date">Arrival Date: {{ $date.formatUtc(shipment.ArrivalDate, 'yyyy-M-d') }}
      </span>
    </div>
    <div class="card-main">
      <div class="card-main-inner">
        <div class="card-row shipper">
          <div class="item-description">
            <div class="option-icon">
              <nuxt-link
                :to="getPath('shipper')"
                class="fa fa-search"/>
            </div>
            <h4>Shipper</h4>
            <h3>{{ shipment.Shipper }}</h3>
          </div>
        </div>
        <div class="card-row consignee">
          <div class="item-description">
            <div class="option-icon">
              <nuxt-link
                :to="getPath('consignee')"
                class="fa fa-search"/>
            </div>
            <h4>Consignee</h4>
            <h3>{{ shipment.Consignee }}</h3>
          </div>
        </div>
        <div class="card-row">
          <div class="item-description">
            <h4>Gross Weight</h4>
            <h3>{{ $numeral(shipment.GrossWeightLbs).format('0,0') }} lbs</h3>
          </div>
        </div>
        <div class="card-row">
          <div class="item-description">
            <h4>Product Description</h4>
            <h3 class="prod-desc">{{ shipment.ProductDescription.join(', ') }}</h3>
          </div>
        </div>
      </div>
    </div>
    <div class="card-footer">
      <nuxt-link
        :to="getPath('product-description')"
        class="btn-text">Full Details</nuxt-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'ShipmentCard',
  props: {
    shipment: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapState({
      country: state => state.country,
      tradeType: state => state.tradeType
    })
  },
  methods: {
    getPath(val) {
      const { locale } = this.$i18n
      if (val === 'product-description') {
        return {
          name: `country-tradeType-shipment-id___${locale}`,
          params: {
            id: this.shipment.ShipmentId,
            country: this.country,
            tradeType: this.tradeType
          }
        }
      } else if (val === 'consignee') {
        return {
          name: `company-id-overview___${locale}`,
          params: {
            id: this.shipment.ConsigneeId
          }
        }
      } else if (val === 'shipper') {
        return {
          name: `company-id-overview___${locale}`,
          params: {
            id: this.shipment.ShipperId
          }
        }
      }
    }
  }
}
</script>
