<template>
  <div class="option-fields">
    <el-tooltip content="Customize View">
      <i
        tabindex="0"
        class="views-icon"
        @click.prevent="open" />
    </el-tooltip>
    <el-dialog
      :visible.sync="isOpen"
      :before-close="close"
      title="Manage Your Custom View">
      <table
        v-if="fieldsIds.length"
        class="table table-bordered">
        <tbody>
          <tr>
            <discard-view-prompt
              v-if="!selectedView.readonly && isEditing && isDiscardingEdit"
              :animate="animateDiscard"
              @discard="discardAndSelectView"
              @discard-mobile="resetMove"
              @cancel="removeConfirmEdit"/>
          </tr>
          <tr>
            <td
              :class="{ 'move-left': moveLeft }"
              class="view-listings view-list-for-mobile mh1">
              <view-selector
                :views="views"
                :selected-view="selectedView"
                :new-view="newView"
                @select="selectView"
                @change="promptDiscard"
                @change-mobile="setView"/>
            </td>
            <td
              :class="{
                'move-right': moveRight && !isEditing
              }"
              class="middle-col mh2">
              <view-fields-area
                :original-fields="selectedView.items || []"
                :selected-fields="editedFields"
                :fields="customFieldsAvailable"
                :editing="isEditing"
                @change="editedFields = $event"
                @remove="removeField"/>
            </td>
            <td
              v-if="isEditing"
              class="col-md-5 animated fadeIn fields-col afc-mobile mh3 move-right">
              <remaining-fields
                :fields="customFieldsAvailable"
                :active-fields="editedFields"
                @add-field="addField"
                @remove-field="removeField"
                @add-all="addAllFields"
                @clear-all="clearFields"
                @filter-changed="filter = $event"/>
            </td>
          </tr>
          <tr class="bottom-toolbar align-right">
            <td
              :class="{ 'move-left': moveLeft }"
              class="relative-pos mh1"
              colspan="2">
              <button
                v-if="!selectedView.readonly && !isEditing && !isNaming"
                class="btn btn-text secondary edit"
                @click="editView">Edit View</button>
              <new-view-prompt
                :views="views"
                :active="isNaming"
                :pending="newView.name.length > 0"
                :editing="isEditing"
                @open="nameNewView"
                @create="createNewView"
                @cancel="closeNaming"/>
            </td>
            <td
              v-if="isEditing"
              :class="{ 'for-zindex': isDeleting }"
              class="vertical-middle fields-col align-right mobile-col-btn mh3 move-right">
              <template v-if="currentlyProcessing">
                <div class="saving-container">
                  <div class="alert-container">
                    <div class="c-process">
                      {{
                        findPendingViews(selectedView).process === 'save'
                          ? 'Saving...'
                          : 'Deleting...'
                      }}
                    </div>
                    <img
                      class="spinner"
                      src="~/assets/img/spinner.svg" >
                  </div>
                </div>
              </template>
              <template
                v-else-if="
                  !doneProcessing() && !isDiscardingEdit && !selectedView.readonly
                ">
                <button
                  v-if="viewChanged && !isDiscardingEdit"
                  class="btn btn-text secondary cancel"
                  @click="cancel">Cancel</button>
                <delete-view-prompt
                  v-if="!viewChanged"
                  :active="isDeleting"
                  :view="selectedView"
                  @open="confirmDelete"
                  @delete="removeView(selectedView)"
                  @delete-mobile="removeViewMobile(selectedView)"
                  @cancel="editView"/>
                <button
                  v-else
                  class="btn btn-default btn-primary apply"
                  @click="
                    saveChanges({
                      view: selectedView,
                      fields: editedFields
                    })
                  ">Save View</button>
              </template>
              <div
                v-else-if="!isDiscardingEdit"
                class="alert-container">
                <span
                  v-if="lastProcessStatus"
                  class="save-success">
                  <i class="checkmark-icon-default green-color medium" />
                  <span>Saved!</span>
                </span>
                <span
                  v-else
                  class="save-failed">
                  <i class="fa fa-exclamation-triangle" />
                  <span>Failed!</span>
                </span>
              </div>

              <button
                v-if="!viewChanged && !isDiscardingEdit"
                class="btn btn-default btn-primary done"
                @click="reset">Done</button>
            </td>
          </tr>
        </tbody>
      </table>
    </el-dialog>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { arrayContainsSame } from '~/utils/array'
import ViewSelector from './ViewSelector'
import ViewFieldsArea from './ViewFieldsArea'
import RemainingFields from './RemainingFields/RemainingFields'
import NewViewPrompt from './NewViewPrompt'
import DeleteViewPrompt from './DeleteViewPrompt'
import DiscardViewPrompt from './DiscardViewPrompt'

export default {
  components: {
    ViewSelector,
    ViewFieldsArea,
    RemainingFields,
    NewViewPrompt,
    DeleteViewPrompt,
    DiscardViewPrompt
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      filter: '',

      moveLeft: false,
      moveRight: false,

      isOpen: false,
      isEditing: false,
      isNaming: false,
      isDeleting: false,

      viewToSelectAfterDiscard: '',
      isDiscardingEdit: false,
      animateDiscard: false,

      pendingViews: [],
      lastProcessStatus: null,

      newView: { name: '', items: [], readonly: false },
      selectedView: this.activeShipmentsView,
      prevSelectedView: this.selectedView,
      closeAfterDiscard: false,

      editedFields: []
    }
  },

  computed: {
    ...mapState('views', ['views', 'fields', 'activeShipmentsView']),
    ...mapGetters('views', ['customFieldsAvailable']),
    ...mapState('tours', ['FirstLoginTour']),
    fieldsIds() {
      return Object.keys(this.customFieldsAvailable)
    },
    isStrictlyEditing() {
      return this.isEditing && this.selectedView !== this.newView
    },

    viewChanged() {
      return (
        (this.isEditing && this.selectedView === this.newView) ||
        !arrayContainsSame(this.selectedView.items || [], this.editedFields)
      )
    },

    currentlyProcessing() {
      const transactionFound = _.find(
        this.pendingViews,
        transaction => transaction.view === this.selectedView
      )

      return transactionFound ? transactionFound.pending : false
    },

    currentProcess() {
      return this.findPendingViews(this.selectedView).process === 'save'
        ? 'Saving...'
        : 'Deleting...'
    }
  },
  watch: {
    activeShipmentsView: {
      immediate: true,
      handler(activeShipmentsView) {
        this.selectedView = activeShipmentsView
      }
    },
    isOpen(newVal) {
      if (this.FirstLoginTour.currentStep !== -1) {
        this.toggleTour({
          tour: 'FirstLoginTour',
          show: !newVal
        })
      }
    }
  },

  methods: {
    ...mapMutations('views', ['setActiveShipmentsView']),
    ...mapMutations('tours', ['toggleTour']),
    ...mapActions('views', ['saveView', 'deleteView']),
    open() {
      this.reset()
      if (this.isDisabled) return
      this.isOpen = true
      this.$emit('view-modal', true)
    },
    close() {
      if (this.viewChanged) {
        this.closeAfterDiscard = true
        this.promptDiscard(this.selectedView)
        return
      }

      this.isOpen = false
      this.$emit('view-modal', false)
    },

    resetMove() {
      this.reset()
      this.moveLeft = false
      this.moveRight = false
    },

    clearFields() {
      this.editedFields = []

      this.clearFinishedSaves()
    },

    addAllFields() {
      const fieldsByPriority = this.$store.getters['views/sortFields'](
        this.fieldsIds
      )

      this.editedFields = _.union(this.editedFields, fieldsByPriority)

      this.clearFinishedSaves()
    },

    doneProcessing() {
      const transactionFound = this.findPendingViews(this.selectedView)

      if (!transactionFound) return false
      this.lastProcessStatus = transactionFound.success
      return true
    },

    cancel() {
      if (this.selectedView === this.newView) {
        this.confirmEdit()
        return
      }
      this.isEditing = true
      this.isDeleting = false
      this.removeConfirmEdit()
      this.isNaming = false
      this.clearFinishedSaves()

      if (this.filter !== '') {
        this.$bus.$emit('focusOnFilterInput')
      }

      this.resetNewView()
      this.selectView(this.activeShipmentsView)
    },

    reset() {
      this.isEditing = false
      this.isDeleting = false
      this.removeConfirmEdit()
      this.isNaming = false
      this.clearFinishedSaves()

      this.resetNewView()
      this.selectView(this.activeShipmentsView)

      this.moveLeft = false
      this.moveRight = false
    },

    selectView(view) {
      this.isEditing =
        this.newView === view || (this.isEditing && this.selectedView === view)
      this.isDeleting = false
      this.removeConfirmEdit()
      this.isNaming = false
      this.clearFinishedSaves()
      this.setView(view)
      this.editedFields = this.selectedView.items
        ? this.selectedView.items.concat()
        : []

      this.moveLeft = true
      this.moveRight = true
    },

    editView() {
      this.isEditing = true
      this.isDeleting = false
      this.removeConfirmEdit()
      this.isNaming = false
      this.moveLeft = true
      this.moveRight = false

      this.editedFields = this.selectedView.items.concat()
    },

    createNewView(name) {
      const newViewName = _.startCase(name.toLowerCase())

      if (this.views.find(view => view.name === newViewName) !== undefined) {
        this.$message({
          message: `${newViewName} already exists`,
          type: 'error'
        })
        return
      }
      this.prevSelectedView = this.selectedView

      const newView = { name: newViewName, items: [], readonly: false }

      this.selectedView = newView
      this.newView = newView
      this.editedFields = []

      this.isEditing = true
      this.isDeleting = false
      this.removeConfirmEdit()
      this.isNaming = false

      this.moveLeft = true
      this.moveRight = true
    },

    nameNewView() {
      this.resetNewView()
      this.isDeleting = false
      this.removeConfirmEdit()
      this.isNaming = true
    },

    closeNaming() {
      this.isNaming = false
    },

    confirmDelete() {
      this.isDeleting = true
    },

    confirmEdit() {
      if (!this.viewChanged) {
        this.resetMove()
        return
      }
      this.isDiscardingEdit = true
    },

    removeConfirmEdit() {
      this.isDiscardingEdit = false
    },

    addField(field) {
      this.editedFields.push(field)
      if (this.isEditing) {
        this.$nextTick(() => {
          // Scroll down when fields are added
          document.querySelector(
            '.clearfix.view-container.view-tab-container'
          ).scrollTop = document.querySelector(
            '.clearfix.view-container.view-tab-container'
          ).scrollHeight
        })
      }
      this.clearFinishedSaves()
    },

    removeField(field) {
      this.editedFields.splice(this.editedFields.indexOf(field), 1)

      this.clearFinishedSaves()
    },

    removeView(view) {
      this.isDeleting = false
      this.removeConfirmEdit()

      this.pendingViews.push({
        view,
        process: 'delete',
        pending: true,
        success: false
      })

      return Promise.resolve(this.deleteView(view))
        .then(() => {
          this.finishProcessing({
            view,
            process: 'delete',
            status: true
          })
          if (this.selectedView === view) {
            this.selectView(_.head(this.views))
          }
        })
        .catch(() => {
          this.$message({
            message: 'An error occurred, please refresh and try again.',
            type: 'error'
          })
          this.finishProcessing({
            view,
            process: 'delete',
            status: false
          })
        })
    },

    removeViewMobile(view) {
      return this.removeView(view).then(() => {
        this.moveLeft = false
        this.moveRight = false
      })
    },

    finishProcessing({ view, process, status }) {
      const transaction = this.findPendingViews(view)

      transaction.process = process
      transaction.pending = false
      transaction.success = status

      setTimeout(this.removeFromPending.bind(this, transaction), 1500)
    },

    removeFromPending(target) {
      this.pendingViews = _.filter(
        this.pendingViews,
        transaction => transaction !== target
      )
    },

    findPendingViews(view) {
      return _.find(this.pendingViews, transaction => transaction.view === view)
    },

    clearFinishedSaves() {
      this.pendingViews = _.filter(
        this.pendingViews,
        transaction => transaction.pending
      )
    },

    saveChanges({ view, fields }) {
      this.pendingViews.push({
        view,
        process: 'save',
        pending: true,
        success: false
      })
      return Promise.resolve(this.saveView({ view, fields }))
        .then(() => {
          const savedView = this.views.find(temp => temp.name === view.name)
          this.resetNewView()
          this.selectView(savedView)
          this.finishProcessing({ view, process: 'save', status: true })
        })
        .catch(() => {
          this.$message({
            message: 'An error occurred, please refresh and try again.',
            type: 'error'
          })
          this.finishProcessing({
            view,
            process: 'save',
            status: false
          })
        })
    },

    saveChangesMobile({ view, fields }) {
      return this.saveChanges({ view, fields }).then(() => {
        this.moveLeft = false
        this.moveRight = false
      })
    },

    setView(view) {
      this.selectedView = view
      this.setActiveShipmentsView(view)
    },

    promptDiscard(view) {
      if (!this.viewChanged) {
        return this.selectView(view)
      }

      this.confirmDiscard()
      this.isDiscardingEdit = true

      if (this.prevSelectedView) {
        this.viewToSelectAfterDiscard = this.prevSelectedView
      } else {
        this.viewToSelectAfterDiscard = view
      }

      this.prevSelectedView = null
    },

    confirmDiscard() {
      if (!this.animateDiscard) {
        this.animateDiscard = true
        setTimeout(() => {
          this.animateDiscard = false
        }, 1500)
      }
    },

    discardAndSelectView() {
      this.isEditing = false
      this.isDeleting = false
      this.removeConfirmEdit()
      this.isNaming = false
      this.clearFinishedSaves()

      if (this.filter !== '') {
        this.$bus.$emit('focusOnFilterInput')
      }

      this.resetNewView()
      const viewToSelect =
        this.viewToSelectAfterDiscard !== ''
          ? this.viewToSelectAfterDiscard
          : _.head(this.views)
      this.selectView(viewToSelect)
      this.viewToSelectAfterDiscard = ''

      if (this.closeAfterDiscard) {
        this.closeAfterDiscard = false
        this.isOpen = false
      }
    },

    resetNewView() {
      this.newView = { name: '', items: [], readonly: false }
    }
  }
}
</script>

<style src="~/assets/scss/components/data-viewer/views-menu.scss" lang="scss"/>
