<template>
  <div class="calendar">
    <div class="date-picker-wrapper">
      <el-tooltip
        :disabled="isValidDateRange"
        placement="top"
        effect="light">
        <template slot="content">
          <div class="popover-tooltip-notification small-content">
            <h3>Invalid Date Range</h3>
            <div class="flex-container">
              <i class="error-icon" />
              <p>
                Your selected date range is outside your subscription.
                <a
                  href="#"
                  class="upgrade-download inline-normal"
                  @click="openUpgradeModal">Upgrade now</a>
                to search for shipments within this date range.
              </p>
            </div>
          </div>
        </template>
        <date-picker
          :disabled-date-picker="disabledDatePicker"
          :class="{ 'date-picker-error': !isValidDateRange }"
          :picker-options="pickerOptions"
          :is-date-picker-visible="isDatePickerVisible"
          v-model="localizedDateRange"
          :align="align"
          format="yyyy-MM-dd"
          placeholder="Pick a range"
          @showed-date-picker="$emit('showed-date-picker', $event)" />
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'
import DatePicker from './DatePicker'
import DatePickerScale from './DatePickerScale'
import HideIncompleteDataCheckbox from '~/components/search/HideIncompletedataCheckbox'
import AutoscaleAxis from '~/components/search/AutoscaleAxis'

export default {
  name: 'DateRangeSelector',
  components: {
    DatePicker,
    DatePickerScale,
    HideIncompleteDataCheckbox,
    AutoscaleAxis
  },
  mixins: [openUpgradeModal],
  props: {
    dateRanges: {
      type: Array,
      required: true
    },
    selectedRange: {
      type: Object,
      required: true
    },
    minDate: {
      type: Number,
      required: true
    },
    maxDate: {
      type: Number,
      required: true
    },
    isUtc: {
      type: Boolean,
      default: true
    },
    align: {
      type: String,
      default: 'right'
    },
    isBookmark: {
      type: Boolean,
      default: false
    },
    disabledDatePicker: {
      type: Boolean,
      default: () => false
    },
    isDatePickerVisible: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    const self = this

    return {
      pickerOptions: {
        disabledDate(date) {
          const minDate = self.localizedMinDate
          const maxDate = self.localizedMaxDate
          const subsMinDate = self.localizedSubscriptionMinDate
          const subsMaxDate = self.localizedSubscriptionMaxDate

          if (self.isUtc && (date < minDate || date > maxDate)) {
            return 'unavailable'
          }
          if (date > subsMaxDate || date < subsMinDate) {
            return self.isUtc ? 'upgradeable' : 'unavailable'
          }

          return 'available'
        },
        shortcuts: []
      }
    }
  },
  computed: {
    ...mapGetters('userSubscriptions', ['subscriptionDateRange']),
    isValidDateRange() {
      const { from, to } = this.subscriptionDateRange
      const { from: mFrom, to: mTo } = this.selectedRange
      if (from <= mFrom && to >= mTo) {
        return true
      }

      return false
    },
    localizedDateRange: {
      get() {
        const { from, to } = this.selectedRange
        this.$emit('validate-date-range', this.isValidDateRange)

        return this.isUtc
          ? [from, to].map(date => this.$date.getLocalDate(date))
          : [from, to]
      },
      set([from, to]) {
        this.setDateRange({ from, to })
      }
    },
    localizedMinDate() {
      return this.isUtc
        ? this.$date.getLocalDate(this.minDate).getTime()
        : this.minDate
    },
    localizedMaxDate() {
      return this.isUtc
        ? this.$date.getLocalDate(this.maxDate).getTime()
        : this.maxDate
    },
    localizedSubscriptionMinDate() {
      return this.isUtc
        ? this.$date.getLocalDate(this.subscriptionDateRange.from).getTime()
        : this.minDate
    },
    localizedSubscriptionMaxDate() {
      return this.isUtc
        ? this.$date.getLocalDate(this.subscriptionDateRange.to).getTime()
        : this.maxDate
    },
    localizedSubscriptionDateRange() {
      return {
        from: this.localizedSubscriptionMinDate,
        to: this.localizedSubscriptionMaxDate
      }
    }
  },
  mounted() {
    const self = this
    this.dateRanges.map(({ text, from, to }) => {
      this.pickerOptions.shortcuts.push({
        text,
        from: this.isUtc ? this.$date.getLocalDate(from).getTime() : from,
        to: this.isUtc ? this.$date.getLocalDate(to).getTime() : to,
        subscriptionDateRange: self.localizedSubscriptionDateRange,
        minDate: self.localizedMinDate,
        maxDate: self.localizedMaxDate,
        value: self.localizedDateRange,
        onClick(picker) {
          picker.$emit('pick', [this.from, this.to])
        }
      })
    })
  },
  methods: {
    setDateRange({ from, to }) {
      if (from === null || to === null) {
        return
      }

      this.$emit('daterange', { from, to })
    }
  }
}
</script>
