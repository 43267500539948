<template>
  <div class="date-inputs">
    <div class="date-input-wrapper">
      <h3>Start Date</h3>
      <date-input
        ref="minDate"
        v-model="start"
        :error="startError"
        :disabled-date="disabledDate"
        size="small"
        @error="startError = $event"
        @input="onStart"/>
    </div>
    <div class="date-input-wrapper">
      <h3>End Date</h3>
      <date-input
        ref="maxDate"
        v-model="end"
        :disabled-date="disabledDate"
        :error="endError"
        size="small"
        @error="endError = $event"
        @input="onEnd"/>
    </div>
  </div>
</template>
<script>
import DateInput from './DateInput'

export default {
  name: 'DateInputs',
  components: { DateInput },
  props: {
    dates: {
      type: Array,
      required: true
    },
    disabledDate: {
      type: Function,
      required: true
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    }
  },
  data() {
    return {
      start: null,
      end: null,
      startError: '',
      endError: ''
    }
  },
  watch: {
    minDate: {
      immediate: true,
      handler(val) {
        if (!val) {
          return
        }
        this.startError = ''
        this.start = new Date(val)
      }
    },
    maxDate: {
      immediate: true,
      handler(val) {
        if (!val) {
          return // will be null while user is selecting in date-table
        }
        this.endError = ''
        this.end = new Date(val)
      }
    },
    startError() {
      if (this.startError) {
        this.$emit('error', true)
        return
      }
      this.$emit('error', false)
    },
    endError() {
      if (this.endError) {
        this.$emit('error', true)
        return
      }
      this.$emit('error', false)
    }
  },
  methods: {
    focus(index = 0) {
      const inputs = ['minDate', 'maxDate']
      this.$refs[inputs[index]].focus()
    },
    onStart(value) {
      const disabled = this.disabledDate(value)
      if (disabled !== 'available') {
        this.startError = disabled
      }
      if (!value) {
        this.startError = 'No start date value'
      }

      if (value > this.end) {
        this.startError = 'Start date is after end date.'
      }

      if (this.startError || this.endError) return

      this.$emit('error', false)
      // this.$refs.maxDate.focus()
      this.emitPick()
    },
    onEnd(value) {
      const disabled = this.disabledDate(value)
      if (disabled !== 'available') {
        this.endError = disabled
      }
      if (!value) {
        this.endError = 'No end date value'
      }
      if (value < this.start) {
        this.endError = 'End date is earlier than start date'
      }
      if (this.startError || this.endError) return
      this.emitPick()
    },
    clearErrors() {
      this.startError = ''
      this.endError = ''
    },
    emitPick() {
      this.$emit('pick', {
        minDate: this.start,
        maxDate: this.end
      })
    }
  }
}
</script>
<style lang="scss">
.date-inputs {
  display: flex;
  justify-content: space-between;

  .date-input-wrapper {
    width: 100%;
    padding: 12px 16px 0;
    padding-top: 0;

    .el-date-editor {
      width: 100%;

      .el-input__inner {
        margin: 0;
      }
    }

    @media #{$small-only} {
      .el-input__inner {
        min-width: 0;
      }
    }

    .el-input--prefix {
      &.error {
        .el-input__prefix {
          .icon-date {
            @include opacity(0.2);
          }
        }
      }

      .el-input__prefix {
        .icon-date {
          cursor: auto;
        }
      }
    }
    .el-input--small {
      .el-input__inner {
        margin: 0;
        line-height: 1 !important;
        cursor: text;
      }
    }

    h3 {
      margin-bottom: 4px;
    }
    input {
      &.el-input__inner {
        color: #344050;
      }
    }
  }
}

.el-input {
  &.has-error {
    .el-input__inner {
      border-color: $brand-red-color;
      @include opacity(1);
      background: #fff;
      color: rgba($brand-text-color, 0.2);
    }
  }
}

input.el-input__inner::-webkit-calendar-picker-indicator {
  display: none !important;
}
input.el-input__inner[type='date']::-webkit-input-placeholder {
  visibility: hidden !important;
}
</style>
