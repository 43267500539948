<template>
  <div class="confirm-delete">
    <span 
      v-if="active" 
      class="web-only">
      <span>Are you sure you want to delete {{ view.name }}?</span>
      <button 
        class="btn-text delete secondary yes" 
        @click="confirmDelete">
        Yes
      </button>
      <button 
        class="btn-text delete no" 
        @click="cancel">No</button>
    </span>
    <div 
      v-if="active" 
      class="global-confirmation" 
      @click.prevent="cancel">
      <div class="btn-tools delete-tools slideUp">
        <h2>Are you sure you want to delete {{ view.name }}?</h2>
        <a 
          class="btn btn-text secondary" 
          @click.prevent="confirmDeleteMobile">
          Yes
        </a>
        <a 
          class="btn btn-text" 
          @click.prevent="cancel"> No </a>
      </div>
    </div>
    <button 
      v-else 
      class="btn btn-text secondary delete" 
      @click="prompt">
      Delete View
    </button>
  </div>
</template>
<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true
    },
    view: {
      type: Object,
      required: true
    }
  },

  methods: {
    prompt() {
      this.$emit('open')
    },

    cancel() {
      this.$emit('cancel')
    },

    confirmDelete() {
      this.$emit('delete')
    },

    confirmDeleteMobile() {
      this.$emit('delete-mobile')
    }
  }
}
</script>
