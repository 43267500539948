<template>
  <div>
    <span
      :class="{ shake: animate }"
      class="discard-changes web-only">
      Do you want to discard your changes? &nbsp;
      <button
        class="btn-text delete secondary"
        @click="discardEdit">
        Yes &nbsp;
      </button>
      <button
        class="btn-text delete"
        @click="cancel">No</button>
    </span>
    <div
      class="global-confirmation"
      @click.prevent="cancel">
      <div class="btn-tools delete-tools slideUp">
        <h2>Do you want to discard your changes?</h2>
        <a
          class="btn btn-text secondary"
          @click.prevent="discardEditMobile">
          Yes
        </a>
        <a
          class="btn btn-text"
          @click.prevent="cancel"> No </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    animate: {
      type: Boolean,
      required: true
    }
  },

  methods: {
    cancel() {
      this.$emit('cancel')
    },

    discardEdit() {
      this.$emit('discard')
    },

    discardEditMobile() {
      this.$emit('discard-mobile')
    }
  }
}
</script>
