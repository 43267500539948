export default {
  parentName: {
    type: String,
    default() {
      return this.$options.name
    }
  },
  isVisible: {
    type: Boolean,
    default: true
  },
  loading: {
    type: Boolean,
    default: false
  },
  limit: {
    type: Number,
    default: 10000
  },
  // pagination props
  total: {
    type: Number,
    default: 0
  },
  currentPage: {
    type: Number,
    required: true
  },
  pageLimit: {
    type: Number,
    required: true
  },
  // table props
  sortField: {
    type: String,
    required: true
  },
  sortOrder: {
    type: String,
    required: true
  },
  showCustomView: {
    type: Boolean,
    default: false
  },
  hasCheckboxes: {
    type: Boolean,
    default: false
  },
  floatingScrollbarOptions: {
    type: Object,
    default() {
      return {}
    }
  }
}
