<template>
  <div
    v-loading="$actions.pending('company/overview/fetchCompanyTraders')"
    v-show="$actions.pending('company/overview/fetchCompanyTraders') || chartData.data.length > 0"
    class="pie-item">
    <h2>Top {{ companyType | companyTypeFilter }} {{ dateRangeText }}</h2>

    <pie-chart
      :data="chartData.data"
      :legend="chartData.legend"
      pie-class="overview-pie-chart"/>
  </div>
</template>

<script>
import { sum, sumBy } from 'lodash'
import { mapState, mapGetters } from 'vuex'
import PieChart from '~/components/charts/PieChart'

export default {
  name: 'TopCompaniesChart',
  components: { PieChart },
  filters: {
    companyTypeFilter(type) {
      switch (type) {
        case 'consignees':
          return 'importers'
        case 'shippers':
          return 'suppliers'
        default:
          return 'companies'
      }
    }
  },

  props: {
    companyType: {
      type: String,
      required: true,
      validator(value) {
        switch (value) {
          case 'consignees':
          case 'shippers':
            return true
          default:
            return false
        }
      }
    }
  },

  computed: {
    ...mapState('company/overview', ['topProducts']),
    ...mapGetters('company/overview', ['dateRangeText']),
    chartData() {
      const companies = this.topProducts[this.companyType]

      const data = []
      const legend = []
      const ids = []
      const minimumLength = companies.length > 6 ? 5 : 6
      const length = Math.min(companies.length, minimumLength)

      for (let i = 0; i < length; i++) {
        const company = companies[i]
        data.push(company.shipmentCount)
        legend.push({
          label: company.name,
          link: `/company/${company.id}/overview`
        })
        ids.push(company.id)
      }

      if (data.length === 5 && companies.length > 6) {
        const othersCount = sumBy(companies, 'shipmentCount') - sum(data)
        data.push(othersCount)
        legend.push({
          label: `OTHER ${this.$options.filters
            .companyTypeFilter(this.companyType)
            .toUpperCase()}`,
          link: ''
        })
        ids.push('')
      }

      return {
        data,
        legend,
        ids
      }
    },
    isLoading() {
      const actionString = (() => {
        switch (this.companyType) {
          case 'consignees':
            return 'company/importers/fetchCompanyConsignees'
          case 'shippers':
            return 'company/suppliers/fetchCompanySuppliers'
        }
      })()
      return this.$actions.pending(actionString)
    }
  }
}
</script>
