<template>
  <div class="pie-chart-legend">
    <h4>LEGEND</h4>
    <ul>
      <li
        v-for="(item, $index) in legend"
        :key="$index">
        <div
          class="label-wrapper"
          @mouseenter="$emit('hover', $index)"
          @mouseleave="$emit('hover', -1)">
          <span
            :style="{ 'background-color': item.color }"
            class="color-block"/>
          <template>
            <nuxt-link
              v-if="item.link"
              :to="item.link"
              :custom-class="'label'"
              v-html="`${item.label}`"/>
            <span
              v-else
              class="label">
              {{ item.label }}
            </span>
          </template>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  name: 'PieChartLegend',
  props: {
    legend: {
      type: Array,
      required: true
    }
  }
}
</script>
