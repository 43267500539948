import { Pie } from 'vue-chartjs'
import chartMixin from '../mixins/chartMixin'

let TweenLite = null

export default {
  name: 'Chart',
  extends: Pie,
  mixins: [chartMixin],
  props: {
    colors: {
      type: Array,
      default: null
    }
  },
  computed: {
    chartOptions() {
      const self = this
      const { padding } = this.config.pie

      return {
        noScales: true,
        layout: {
          padding: {
            top: padding,
            bottom: padding,
            left: padding,
            right: padding
          }
        },
        pieceLabel: {
          ...this.config.pie.pieceLabel,
          render({ percentage }) {
            return percentage + '%'
          }
        },
        animation: {
          animateScale: true,
          animateRotate: true
        },
        onHover(event, elements) {
          if (elements[0] === self.activeElements[0]) {
            return
          }

          // Check if mouse is hovering over the empty area between slice and center
          if (self.activeElements.length > 0 && elements.length === 0) {
            const [element] = self.activeElements
            let { top, bottom, right, left } = element._chart.chartArea
            const chartCenter = {
              x: (left + right) / 2,
              y: (top + bottom) / 2
            }

            const { offsetX: x, offsetY: y } = event
            const distance = Math.sqrt(
              Math.pow(x - chartCenter.x, 2) + Math.pow(y - chartCenter.y, 2)
            )

            if (distance < element._model.outerRadius) {
              return
            }
          }

          self.activeElements = elements
        }
      }
    },
    chartData() {
      const { data, labels, colors, config } = this
      return {
        labels,
        datasets: [
          {
            data,
            backgroundColor: colors,
            hoverBorderWidth: config.pie.border.width,
            borderWidth: config.pie.border.width,
            hoverBackgroundColor: colors,
            hoverBorderColor: colors
          }
        ]
      }
    }
  },
  watch: {
    activeElements(newElements, oldElements) {
      oldElements.forEach(element => {
        const { top, bottom, right, left } = element._chart.chartArea
        const chartCenter = {
          x: (left + right) / 2,
          y: (top + bottom) / 2
        }
        TweenLite.to([element._view], 0.5, chartCenter)
        this.tooltip.translateTo(element.__tooltip)
      })

      newElements.forEach(element => {
        element.__tooltip = { x: this.tooltip.x, y: this.tooltip.y }
        const { startAngle, endAngle } = element._view
        const radians = (startAngle + endAngle) / 2
        const translationDistance = this.config.pie.padding
        const xDiff = translationDistance * Math.cos(radians)
        const yDiff = translationDistance * Math.sin(radians)
        TweenLite.to([element._view], 0.5, {
          x: element._view.x + xDiff,
          y: element._view.y + yDiff
        })

        this.tooltip.translateTo({
          x: this.tooltip.x + xDiff,
          y: this.tooltip.y + yDiff
        })
      })
    }
  },
  data() {
    return {
      activeElements: [],
      hoveredElements: null,
      tooltipTween: null
    }
  },
  created() {
    TweenLite = require('gsap/TweenLite').default
    this.addPlugin(require('chart.piecelabel.js'))
  }
}
