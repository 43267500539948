<template>
  <div>
    <div class="icon-container">
      <span class="chart-group">
        <i class="line-chart-icon"/>
        <i class="table-shape-icon"/>
        <i class="circle circle-top"/>
        <i class="pie-shape-icon"/>
        <i class="calendar-shape-icon"/>
        <i class="bar-shape-icon"/>
        <i class="circle circle-bottom"/>
      </span>
      <i class="notification-bell-icon"/>
    </div>
    <h2>{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: 'EmptyStateNotification',
  props: {
    title: {
      type: String,
      required: true
    }
  }
}
</script>
