<template>
  <div class="stats-dropdowns pull-left">
    <multiselect
      :value="selectedChoice"
      :options="availableChoices"
      :searchable="false"
      :show-labels="false"
      :allow-empty="false"
      :hide-selected="true"
      class="custom-select"
      track-by="id"
      @input="choose">
      <template
        slot="singleLabel"
        slot-scope="props">
        {{ props.option.label }}
        <span class="stat-count">
          {{ displayChoice(props.option).value }}
          <span
            v-for="suffix in displayChoice(props.option).suffixes"
            :key="suffix"
            class="item-count-suffix">
            {{ suffix }}
          </span>
        </span>
      </template>
      <template
        slot="option"
        slot-scope="props">
        {{ props.option.label }}
        <span
          class="item-count">
          {{ displayChoice(props.option).value }}
          <span
            v-for="suffix in displayChoice(props.option).suffixes"
            :key="suffix"
            class="item-count-suffix">
            {{ suffix }}
          </span>
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import mixins from './mixins'

export default {
  mixins: [mixins]
}
</script>
