import register from './register'
import KEYS from './KEYS'
import KeyboardEventsHandler from './KeyboardEventsHandler'

export { default as register } from './register'
export { default as KEYS } from './KEYS'
export { default as KeyboardEventsHandler } from './KeyboardEventsHandler'

export default {
  register,
  KEYS,
  KeyboardEventsHandler
}
