<template>
  <el-checkbox v-model="autoscaleYAxis">
    Autoscale y-axis
  </el-checkbox>
</template>

<script>
export default {
  name: 'AutoscaleAxis',
  computed: {
    autoscaleYAxis: {
      get() {
        return this.$store.state.autoscaleYAxis
      },
      set(autoscaleYAxis) {
        this.$store.commit('setAutoscaleYAxis', autoscaleYAxis)
      }
    }
  }
}
</script>
