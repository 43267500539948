import { merge, trim } from 'lodash'
import { chart as chartConfig } from '~/config/config'
import tooltipsMixin from './tooltipsMixin'
import renderMixin from './renderMixin'

/**
 * Possible callback methods:
 * 1. xAxisCallback(label)
 * 2. yAxisCallback(label)
 * 3. tooltipCallback({title, body, index}
 */
export default {
  mixins: [tooltipsMixin, renderMixin],
  props: {
    labels: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    height: {
      type: [Number, String],
      default: null
    },
    width: {
      type: [Number, String],
      default: null
    },
    options: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      isRendered: false
    }
  },
  computed: {
    config() {
      return this.copy(chartConfig)
    },
    mergedOptions() {
      const self = this

      const {
        chartOptions: computedOptions = {},
        config,
        options: propOptions
      } = self

      const animationDuration = this.$store.state.isMounted
        ? config.options.animation.duration
        : 0

      const baseOptions = {
        responsive: true,
        tooltips: this.tooltips,
        legend: { display: false },
        maintainAspectRatio: false,
        hover: {
          mode: 'point',
          intersect: false
        },
        layout: {
          padding: 0
        },
        animation: {
          ...config.options.animation,
          duration: animationDuration
          // onComplete() {
          //   // Make sure data is populated because data.length === 0 still runs onComplete
          //   if (self.chartData.datasets[0].data.length === 0) {
          //     return console.log('data not populated?')
          //   }
          //   self.isRendered = true
          //   this.options.animation.onComplete = undefined
          //   this.options.animation.duration = config.options.animation.duration
          //   console.log('animation duration set to', this.options.animation.duration)
          // }
        }
      }

      if (!computedOptions.noScales) {
        baseOptions.scales = this.copy(config.options.scales)
        baseOptions.scales.yAxes[0].ticks.callback = label => {
          const value = trim(label.toString(), '0')
          if (value.length > 1) {
            return self.$numeral(label).format('0.0a')
          }
          return self.$numeral(label).format('0a')
        }
      }
      // Show tooltips on xAxisHover
      // function onAxisHover is in tooltipsMixin
      if (computedOptions.tooltips && computedOptions.tooltips.xAxis) {
        baseOptions.onHover = this.onAxisHover.bind(this)
      }

      const axes = ['x', 'y']
      axes.forEach(axis => {
        const propName = `${axis}AxisCallback`
        const callback = propOptions[propName] || this[propName]
        if (callback) {
          const axes = `${axis}Axes`
          baseOptions.scales[axes][0].ticks.callback = label => {
            return callback(label)
          }
        }
      })

      return merge({}, baseOptions, propOptions, computedOptions)
    }
  }
}
