<template>
  <div class="section-stats">
    <a
      v-for="choice in choices"
      :key="choice.id"
      :class="{ active: choice.id === selected }"
      href="javascript:void(null)"
      @click.prevent="choose(choice)">
      <h3>{{ choice.label }}</h3>
      <div>
        <tween-number
          :value="displayChoice(choice).value"
          :formatter="format"
          class="stat-count"/>
        <span
          v-for="suffix in displayChoice(choice).suffixes"
          :key="suffix"
          class="stat-suffix">
          {{ suffix }}
        </span>
      </div>
    </a>
  </div>
</template>

<script>
import TweenNumber from '~/components/common/TweenNumber'
import mixins from './mixins'

export default {
  components: {
    TweenNumber
  },
  mixins: [mixins],
  methods: {
    format(value) {
      const numberSplit = value.toString().split('.')
      const decimalValue = numberSplit[1] || ''
      const decimals = decimalValue.length >= 2 ? 2 : decimalValue.length
      return value.toLocaleString('en', {
        minimumFractionDigits: decimals,
        maximumFractionDigits: decimals
      })
    }
  }
}
</script>
