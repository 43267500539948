<template>
  <el-checkbox v-model="isIncompleteDataShown">
    Include incomplete data periods
  </el-checkbox>
</template>

<script>
export default {
  name: 'HideIncompleteDataCheckbox',
  computed: {
    isIncompleteDataShown: {
      get() {
        return this.$store.state.isIncompleteDataShown
      },
      set(isIncompleteDataShown) {
        this.$store.commit('setIsIncompleteDataShown', isIncompleteDataShown)
      }
    }
  }
}
</script>
