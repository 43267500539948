<template>
  <div class="date-tables">
    <div class="date-tables__panel is-left">
      <el-popover
        :disabled="!(prevMonthDisabled && prevYearDisabled)"
        placement="bottom"
        trigger="click">
        <div
          slot="reference"
          class="date-tables__panel__header">
          <button
            :class="{disabled: prevYearDisabled}"
            type="button"
            class="date-tables__icon-btn el-icon-d-arrow-left"
            @click="prevYear" />
          <button
            :class="{disabled: prevMonthDisabled}"
            type="button"
            class="date-tables__icon-btn el-icon-arrow-left"
            @click="prevMonth"/>
          <div>{{ leftLabel }}</div>

        </div>
        <upgrade-account-popover
          v-if="prevNavigationError === 'upgradeable'"
          :close="close"
          additional-desc="<a href='#' class='upgrade-account inline-normal'>Upgrade</a> your account to view more data"/>
        <div
          v-if="prevNavigationError === 'unavailable'"
          class="popover-tooltip-notification small-content">
          <h3>No Available Data</h3>
          <div class="flex-container">
            <i class="calendar-empty-icon" />
            <p>
              No data is available for
              <br >this date
            </p>
          </div>
        </div>
      </el-popover>
      <date-table
        :date="date"
        :year="leftYear"
        :month="leftMonth"
        :min-date="minDate"
        :max-date="maxDate"
        :range-state="rangeState"
        :disabled-date="disabledDate"
        :close="close"
        selection-mode="range"
        @range="$emit('range', $event)"
        @pick="$emit('pick', $event)"/>
    </div>
    <div class="date-tables__panel is-right">
      <el-popover
        :disabled="!(nextMonthDisabled && nextYearDisabled)"
        placement="bottom"
        trigger="click">
        <div
          slot="reference"
          class="date-tables__panel__header">
          <div>{{ rightLabel }}</div>
          <div class="buttons-wrapper">
            <button
              :class="{disabled: nextMonthDisabled}"
              type="button"
              class="date-tables__icon-btn el-icon-arrow-right"
              @click="nextMonth"/>
            <button
              :class="{disabled: nextYearDisabled}"
              type="button"
              class="date-tables__icon-btn el-icon-d-arrow-right"
              @click="nextYear"/>
          </div>
        </div>
        <upgrade-account-popover
          v-if="nextNavigationError === 'upgradeable'"
          :close="close"
          additional-desc="<a href='#' class='upgrade-account inline-normal'>Upgrade</a> your account to view more data"/>
        <div
          v-if="nextNavigationError === 'unavailable'"
          class="popover-tooltip-notification small-content">
          <h3>No Available Data</h3>
          <div class="flex-container">
            <i class="calendar-empty-icon" />
            <p>
              No data is available for
              <br >this date
            </p>
          </div>
        </div>
      </el-popover>
      <date-table
        :date="rightDate"
        :year="rightYear"
        :month="rightMonth"
        :min-date="minDate"
        :max-date="maxDate"
        :range-state="rangeState"
        :disabled-date="disabledDate"
        :close="close"
        selection-mode="range"
        @range="$emit('range', $event)"
        @pick="$emit('pick', $event)"/>
    </div>
  </div>
</template>
<script>
import { t } from 'element-ui/lib/locale'
import { nextMonth, prevMonth } from '../../util/index'
import DateTable from './DateTable'
import UpgradeAccountPopover from '~/components/common/UpgradeAccountPopover'

export default {
  name: 'DateTables',
  components: { DateTable, UpgradeAccountPopover },
  props: {
    disabledDate: {
      type: Function,
      required: true
    },
    close: {
      type: Function,
      required: true
    },
    date: {
      type: Date,
      required: true
    },
    minDate: {
      type: Date,
      default: null
    },
    maxDate: {
      type: Date,
      default: null
    },
    rangeState: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      prevNavigationError: '',
      nextNavigationError: ''
    }
  },
  computed: {
    btnDisabled() {
      return !(this.minDate && this.maxDate && !this.selecting)
    },
    leftLabel() {
      return (
        this.leftDate.getFullYear() +
        ' ' +
        t('el.datepicker.year') +
        ' ' +
        t(`el.datepicker.month${this.leftDate.getMonth() + 1}`)
      )
    },
    rightLabel() {
      return (
        this.rightDate.getFullYear() +
        ' ' +
        t('el.datepicker.year') +
        ' ' +
        t(`el.datepicker.month${this.rightDate.getMonth() + 1}`)
      )
    },
    prevYearDisabled() {
      const date = new Date(this.date)
      date.setFullYear(date.getFullYear() - 1)
      date.setMonth(date.getMonth() - 2)
      return this.validateNavigationState(date, 'prev')
    },
    prevMonthDisabled() {
      const date = prevMonth(new Date(this.date))
      return this.validateNavigationState(prevMonth(date), 'prev')
    },
    nextYearDisabled() {
      const date = new Date(this.date)
      date.setFullYear(date.getFullYear() + 1)

      return this.validateNavigationState(date, 'next')
    },
    nextMonthDisabled() {
      const date = nextMonth(new Date(this.date))
      return this.validateNavigationState(date, 'next')
    },
    leftYear() {
      return this.leftDate.getFullYear()
    },
    leftMonth() {
      return this.leftDate.getMonth()
    },
    rightYear() {
      return this.rightDate.getFullYear()
    },
    rightMonth() {
      return this.rightDate.getMonth()
    },
    rightDate() {
      const newDate = new Date(this.date)
      const month = newDate.getMonth()
      newDate.setDate(1)
      newDate.setMonth(month)

      return newDate
    },
    leftDate() {
      const newDate = new Date(this.date)
      const month = newDate.getMonth()
      newDate.setDate(1)

      if (month === 0) {
        newDate.setFullYear(newDate.getFullYear() - 1)
        newDate.setMonth(11)
      } else {
        newDate.setMonth(month - 1)
      }
      return newDate
    }
  },
  methods: {
    prevMonth() {
      const date = prevMonth(new Date(this.date))

      if (this.prevMonthDisabled) return
      this.$emit('date', date)
    },

    nextMonth() {
      const date = nextMonth(new Date(this.date))

      if (this.nextMonthDisabled) return
      this.$emit('date', date)
    },

    nextYear() {
      const date = new Date(this.date)
      date.setFullYear(date.getFullYear() + 1)

      if (this.nextYearDisabled) return
      this.$emit('date', date)
    },

    prevYear() {
      const date = new Date(this.date)
      date.setFullYear(date.getFullYear() - 1)

      if (this.prevYearDisabled) return
      this.$emit('date', date)
    },
    validateNavigationState(date, navigation) {
      const error = this.disabledDate(date)
      if (error === 'available') {
        return false
      }

      if (navigation === 'prev') {
        this.prevNavigationError = error
        return true
      }

      if (navigation === 'next') {
        this.nextNavigationError = error
        return true
      }
    }
  }
}
</script>
<style src="../../styles/date-tables.scss" lang="scss" />
