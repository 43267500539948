export const numericalFields = [
  'GrossWeightKgs',
  'GrossWeightLbs',
  'HSCode',
  'NumberOfContainers',
  'ArrivalDate',
  'ContainerWidth',
  'ContainerLength',
  'Quantity',
  'ShipmentCount',
  'shipmentsCount',
  'containersCount',
  'weightKg',
  'weightLb',
  'shipmentCount',
  'mostRecentDate',
  'grossWeightInKgs',
  'grossWeightInLbs',
  'CalculatedTEU',
  'calculatedTeu',
  'Measurement'
]
