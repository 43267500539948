<template>
  <el-popover 
    :value="error.length > 0 && popoverVisibility"
    placement="top"
    trigger="manual"
    popper-class="unavailable-upgrade-popover">
    <template v-if="error">
      <div 
        v-if="error === 'unavailable'" 
        class="popover-tooltip-notification small-content">
        <h3>No Available Data</h3>
        <div class="flex-container">
          <i class="calendar-empty-icon" />
          <p>
            No data is available for
            <br >this date
          </p>
        </div>
      </div>
      <div 
        v-else-if="error === 'upgradeable'" 
        class="popover-tooltip-notification small-content">
        <h3>Upgrade Account</h3>
        <div class="flex-container">
          <i class="database-upgrade-icon" />
          <p>
            Upgrade your subscription to view
            <br >data for this date
          </p>
        </div>
      </div>
      <div 
        v-else 
        class="popover-tooltip-notification small-content">
        <h3>Error</h3>
        <div class="flex-container">
          <i class="error-icon" />
          <p>{{ error }}</p>
        </div>
      </div>
    </template>
    <el-input
      ref="input"
      slot="reference"
      :value="displayValue"
      :size="size"
      :class="{ 'has-error': error.length > 0 }" 
      type="text" 
      placeholder="YYYY-MM-DD"
      prefix-icon="icon-date"
      @click.native="selectHandler"
      @focus="popoverVisibility = true"
      @blur="popoverVisibility = false"
      @input="updateValue"/>
  </el-popover>
</template>
<script>
import Locale from 'element-ui/lib/mixins/locale'
import { debounce } from 'lodash'
import { TYPE_VALUE_RESOLVER_MAP, DEFAULT_FORMATS } from '../../util/display'
import { Input } from 'element-ui'
import date from '~/plugins/date/methods'

export default {
  name: 'DateInput',
  components: { ElInput: Input },
  mixins: [Locale],
  props: {
    disabledDate: {
      type: Function,
      required: true
    },
    value: {
      type: Date,
      required: true
    },
    size: {
      type: String,
      default: 'medium'
    },
    error: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      displayValue: '',
      popoverVisibility: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.displayValue = this.formatValue(value)
        // if (this.$refs.input) {
        //   this.$refs.input.currentValue = this.displayValue
        // }
      }
    }
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    selectHandler(e) {
      e.preventDefault()
    },
    updateValue: debounce(function validateDate(value) {
      const parser = TYPE_VALUE_RESOLVER_MAP.date.parser
      const parsedValue = parser(value, DEFAULT_FORMATS.date)

      if (new Date(value) < 1000) {
        this.$emit('error', 'Year is invalid')
        return
      }

      if (!date.isValid(new Date(value)) || !parsedValue) {
        this.$emit('error', 'Date is invalid')
        return
      }

      this.$emit('error', '')
      this.$emit('input', parsedValue)
      // this.displayValue = this.formatValue(parsedValue)
      // this.$refs.input.currentValue = this.displayValue
    }, 500),
    formatValue(value) {
      const formatter = TYPE_VALUE_RESOLVER_MAP.date.formatter
      const format = DEFAULT_FORMATS.daterange

      return formatter(value, format)
    }
  }
}
</script>
