var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"option-fields"},[_c('el-tooltip',{attrs:{"content":"Customize View"}},[_c('i',{staticClass:"views-icon",attrs:{"tabindex":"0"},on:{"click":function($event){$event.preventDefault();return _vm.open($event)}}})]),_vm._v(" "),_c('el-dialog',{attrs:{"visible":_vm.isOpen,"before-close":_vm.close,"title":"Manage Your Custom View"},on:{"update:visible":function($event){_vm.isOpen=$event}}},[(_vm.fieldsIds.length)?_c('table',{staticClass:"table table-bordered"},[_c('tbody',[_c('tr',[(!_vm.selectedView.readonly && _vm.isEditing && _vm.isDiscardingEdit)?_c('discard-view-prompt',{attrs:{"animate":_vm.animateDiscard},on:{"discard":_vm.discardAndSelectView,"discard-mobile":_vm.resetMove,"cancel":_vm.removeConfirmEdit}}):_vm._e()],1),_vm._v(" "),_c('tr',[_c('td',{staticClass:"view-listings view-list-for-mobile mh1",class:{ 'move-left': _vm.moveLeft }},[_c('view-selector',{attrs:{"views":_vm.views,"selected-view":_vm.selectedView,"new-view":_vm.newView},on:{"select":_vm.selectView,"change":_vm.promptDiscard,"change-mobile":_vm.setView}})],1),_vm._v(" "),_c('td',{staticClass:"middle-col mh2",class:{
              'move-right': _vm.moveRight && !_vm.isEditing
            }},[_c('view-fields-area',{attrs:{"original-fields":_vm.selectedView.items || [],"selected-fields":_vm.editedFields,"fields":_vm.customFieldsAvailable,"editing":_vm.isEditing},on:{"change":function($event){_vm.editedFields = $event},"remove":_vm.removeField}})],1),_vm._v(" "),(_vm.isEditing)?_c('td',{staticClass:"col-md-5 animated fadeIn fields-col afc-mobile mh3 move-right"},[_c('remaining-fields',{attrs:{"fields":_vm.customFieldsAvailable,"active-fields":_vm.editedFields},on:{"add-field":_vm.addField,"remove-field":_vm.removeField,"add-all":_vm.addAllFields,"clear-all":_vm.clearFields,"filter-changed":function($event){_vm.filter = $event}}})],1):_vm._e()]),_vm._v(" "),_c('tr',{staticClass:"bottom-toolbar align-right"},[_c('td',{staticClass:"relative-pos mh1",class:{ 'move-left': _vm.moveLeft },attrs:{"colspan":"2"}},[(!_vm.selectedView.readonly && !_vm.isEditing && !_vm.isNaming)?_c('button',{staticClass:"btn btn-text secondary edit",on:{"click":_vm.editView}},[_vm._v("Edit View")]):_vm._e(),_vm._v(" "),_c('new-view-prompt',{attrs:{"views":_vm.views,"active":_vm.isNaming,"pending":_vm.newView.name.length > 0,"editing":_vm.isEditing},on:{"open":_vm.nameNewView,"create":_vm.createNewView,"cancel":_vm.closeNaming}})],1),_vm._v(" "),(_vm.isEditing)?_c('td',{staticClass:"vertical-middle fields-col align-right mobile-col-btn mh3 move-right",class:{ 'for-zindex': _vm.isDeleting }},[(_vm.currentlyProcessing)?[_c('div',{staticClass:"saving-container"},[_c('div',{staticClass:"alert-container"},[_c('div',{staticClass:"c-process"},[_vm._v("\n                    "+_vm._s(_vm.findPendingViews(_vm.selectedView).process === 'save'
                        ? 'Saving...'
                        : 'Deleting...')+"\n                  ")]),_vm._v(" "),_c('img',{staticClass:"spinner",attrs:{"src":require("assets/img/spinner.svg")}})])])]:(
                !_vm.doneProcessing() && !_vm.isDiscardingEdit && !_vm.selectedView.readonly
              )?[(_vm.viewChanged && !_vm.isDiscardingEdit)?_c('button',{staticClass:"btn btn-text secondary cancel",on:{"click":_vm.cancel}},[_vm._v("Cancel")]):_vm._e(),_vm._v(" "),(!_vm.viewChanged)?_c('delete-view-prompt',{attrs:{"active":_vm.isDeleting,"view":_vm.selectedView},on:{"open":_vm.confirmDelete,"delete":function($event){return _vm.removeView(_vm.selectedView)},"delete-mobile":function($event){return _vm.removeViewMobile(_vm.selectedView)},"cancel":_vm.editView}}):_c('button',{staticClass:"btn btn-default btn-primary apply",on:{"click":function($event){return _vm.saveChanges({
                    view: _vm.selectedView,
                    fields: _vm.editedFields
                  })}}},[_vm._v("Save View")])]:(!_vm.isDiscardingEdit)?_c('div',{staticClass:"alert-container"},[(_vm.lastProcessStatus)?_c('span',{staticClass:"save-success"},[_c('i',{staticClass:"checkmark-icon-default green-color medium"}),_vm._v(" "),_c('span',[_vm._v("Saved!")])]):_c('span',{staticClass:"save-failed"},[_c('i',{staticClass:"fa fa-exclamation-triangle"}),_vm._v(" "),_c('span',[_vm._v("Failed!")])])]):_vm._e(),_vm._v(" "),(!_vm.viewChanged && !_vm.isDiscardingEdit)?_c('button',{staticClass:"btn btn-default btn-primary done",on:{"click":_vm.reset}},[_vm._v("Done")]):_vm._e()],2):_vm._e()])])]):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }