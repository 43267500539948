<template>
  <div class="data-table">
    <div
      v-scroll-float-horizontal="{ ...floatingScrollbarOptions, enabled: isVisible }"
      id="dataTable"
      ref="tableWrapper"
      :class="{ 'active': tableWrapperActiveClass }"
      class="table-wrapper">
      <table
        ref="table"
        :class="tableClass"
        class="table table-bordered-row table-hover">
        <colgroup>
          <col v-if="hasCheckboxes">
          <col
            v-for="(header, index) in headers"
            :key="index"
            :class="makeColumnGroupClass(header)">
        </colgroup>
        <thead 
          ref="tableHeaders" 
          class="exp-1">
          <tr>
            <th
              v-if="hasCheckboxes"
              class="column-base check-box">
              <el-checkbox
                v-model="selectedHeaderCheckbox"
                @change="selectAllCheckbox"/>
            </th>
            <th
              v-for="(header, index) in headers"
              :key="index"
              :class="makeHeaderClass(header)"
              @click="emitSort(header)">
              <h3>{{ header.text }}</h3>
              <i
                v-if="header.sortable"
                class="caret"
                aria-hidden="true" />
            </th>
          </tr>
        </thead>
        <tbody ref="tableBody">
          <tr
            v-for="(row, index) in data"
            :key="calculateRowIndex(index)"
            :class="{ selected: isSelected[calculateRowIndex(index)] }">
            <!-- eslint-disable vue/valid-v-model-->
            <td v-if="hasCheckboxes">
              <el-checkbox
                v-model="isSelected[calculateRowIndex(index)]"
                @change="handleCurrentSelection(calculateRowIndex(index))"/>
            </td>
            <!-- eslint-enable vue/valid-v-model-->
            <td
              v-for="(data, index) in row.data"
              :class="[rightAlignedClass(headers[index].id), data.tdClass]"
              :key="index">
              <data-table-cell
                :header="headers[index]"
                :data="data"
                :index="index"
                :has-checkboxes="hasCheckboxes" />
            </td>
            <td
              v-if="showSearchIcon"
              class="search">
              <nuxt-link
                :to="row.link"
                :aria-label="row.link"
                tabindex="-1">
                <i class="fa fa-search" />
              </nuxt-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ColumnResizer from '~/utils/columnResizer'
import { widths } from '~/utils/fieldWidths'
import { numericalFields } from '~/utils/numericalFields'
import DataTableCell from '~/components/data-viewers/DataViewer/DataTableCell'
import { KEYS } from '~/plugins/keyboardEvents'

export default {
  name: 'DataTable',
  components: {
    DataTableCell
  },
  props: {
    parentName: {
      type: String,
      required: true
    },
    isVisible: {
      type: Boolean,
      default: true
    },
    headers: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    sortField: {
      type: String,
      required: true
    },
    sortOrder: {
      type: String,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    },
    pageSize: {
      type: Number,
      required: true
    },
    showSearchIcon: {
      type: Boolean,
      default: false
    },
    tableWrapperActiveClass: {
      type: Boolean,
      default: false
    },
    tableClass: {
      type: Object,
      default() {
        return {}
      }
    },
    startIndex: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    hasCheckboxes: {
      type: Boolean,
      default: false
    },
    disabledColumnResizer: {
      type: Array,
      default() {
        return []
      }
    },
    floatingScrollbarOptions: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      resizer: null,
      scrollReducer: 0,
      tableHeaderCheckbox: {},
      isSelected: {},
      shiftSelectionPivot: undefined,
      shiftSelectionBounds: { min: undefined, max: undefined }
    }
  },
  computed: {
    selectedHeaderCheckbox: {
      get() {
        return this.tableHeaderCheckbox[this.currentPage]
      },
      set(value) {
        this.tableHeaderCheckbox = Object.assign({}, this.tableHeaderCheckbox, {
          [this.currentPage]: value
        })
      }
    },
    resizerOptions() {
      return {
        resizeMode: 'overflow',
        liveDrag: false,
        draggingClass: 'rangeDrag',
        partialRefresh: true,
        disabledColumns: this.disabledColumnResizer,
        serialize: false
      }
    },
    numericalColumnsCustomWidth() {
      const indexes = this.headers
        .map(
          (header, index) =>
            numericalFields.includes(header.id)
              ? index + !!this.hasCheckboxes
              : ''
        )
        .filter(Boolean)

      if (indexes.length) {
        return indexes.map(index => {
          const width = widths.small
          const force = false

          return { index, width, force }
        })
      }

      return false
    },
    bolCustomColumn() {
      const index =
        this.headers.findIndex(header => {
          return header.id.toLowerCase() === 'billoflading'
        }) + !!this.hasCheckboxes

      const width = widths.medium
      const force = false
      return index ? { index, width, force } : null
    },
    productDescriptionCustomColumn() {
      const index =
        this.headers.findIndex(header => {
          return header.id.toLowerCase() === 'productdescription'
        }) + !!this.hasCheckboxes
      const width = widths.large
      const force = false

      return index ? { index, width, force } : null
    },
    productKeywordsCustomColumn() {
      const index =
        this.headers.findIndex(header => {
          return header.id.toLowerCase() === 'productkeywords'
        }) + !!this.hasCheckboxes
      const width = widths.large
      const force = false

      return index ? { index, width, force } : null
    }
  },
  watch: {
    isVisible() {
      this.resizer.syncGrips()
    },
    currentPage() {
      if (this.selectedHeaderCheckbox === void 0) {
        this.selectedHeaderCheckbox = false
      }
    },
    pageSize(newPageSize, oldPageSize) {
      if (
        this.tableHeaderCheckbox[this.currentPage] &&
        newPageSize > oldPageSize &&
        Object.keys(this.isSelected).length !== newPageSize
      ) {
        this.tableHeaderCheckbox[this.currentPage] = false
      } else if (
        this.tableHeaderCheckbox[this.currentPage] &&
        newPageSize > oldPageSize &&
        Object.keys(this.isSelected).length === newPageSize
      ) {
        this.tableHeaderCheckbox[this.currentPage] = true
      } else if (
        !this.tableHeaderCheckbox[this.currentPage] &&
        Object.keys(this.isSelected).length >= newPageSize
      ) {
        this.tableHeaderCheckbox[this.currentPage] = true
      }
    }
  },
  mounted() {
    if (this.selectedHeaderCheckbox === void 0) {
      this.selectedHeaderCheckbox = false
    }

    if (this.hasCheckboxes) {
      this.$bus.$on(
        `clearCheckboxesOf${this.parentName}`,
        this.initializeCheckboxes
      )
    }
    this.moveTableBodyAboveTableHeaders()
    this.initializeResizer()
    this.resizer.syncGrips()
    document.addEventListener('scroll', this.scroll)
    this.$bus.$on('unselect-rows', this.unselectRows)
  },
  beforeUpdate() {
    this.uninitializeResizer()
  },
  updated() {
    this.$nextTick(this.handleUpdate)
  },
  destroyed() {
    if (this.hasCheckboxes) {
      this.$bus.$off(
        `clearCheckboxesOf${this.parentName}`,
        this.initializeCheckboxes
      )
    }
    document.removeEventListener('scroll', this.scroll)
    this.$bus.$off('unselect-rows', this.unselectRows)
  },
  methods: {
    scroll() {
      const headerTopPosition = this.$refs.tableWrapper.getBoundingClientRect()
        .top

      if (headerTopPosition > 47 || headerTopPosition === 0) {
        this.scrollReducer = 0
        this.$refs.tableHeaders.style.transform = 'translate3d(0, 0, 0)'
        return
      } else {
        this.scrollReducer = 46 - headerTopPosition
      }

      if (this.scrollReducer) {
        this.$refs.tableHeaders.style.WebkitTransition = `translate3d(0, ${
          this.scrollReducer
        }px, 1px)`
        this.$refs.tableHeaders.style.transform = `translate3d(0, ${
          this.scrollReducer
        }px, 1px)`
        this.$refs.tableHeaders.style.zIndex = 10
        this.$refs.tableHeaders.style.background = 'white'
        this.$refs.tableHeaders.style.border = '0px'
      }
    },
    moveTableBodyAboveTableHeaders() {
      /*
       *  Fix for tableBody being rendered above tableHeaders when scroll event
       *  updates its transform: translate3d
       */
      const table = this.$refs.table
      const tableHeaders = this.$refs.tableHeaders
      const tableBody = this.$refs.tableBody
      const tableHeadersIndex = Array.prototype.indexOf.call(
        table.children,
        tableHeaders
      )
      const tableBodyIndex = Array.prototype.indexOf.call(
        table.children,
        tableBody
      )

      if (tableHeadersIndex < tableBodyIndex) {
        table.insertBefore(tableBody, tableHeaders)
      }
    },
    initializeResizer() {
      const customColumns = [
        this.productDescriptionCustomColumn,
        this.productKeywordsCustomColumn,
        this.bolCustomColumn,
        ...(this.numericalColumnsCustomWidth
          ? this.numericalColumnsCustomWidth
          : [])
      ].filter(Boolean)

      if (this.$refs.tableWrapper && this.resizer instanceof ColumnResizer) {
        this.resizer.reset(this.resizerOptions, customColumns)
        return
      }
      this.resizer = new ColumnResizer(
        this.$refs.table,
        this.$refs.tableWrapper,
        this.resizerOptions,
        customColumns,
        this
      )
    },
    uninitializeResizer() {
      if (this.resizer instanceof ColumnResizer) {
        this.resizer.reset(
          {
            disable: true
          },
          null
        )
      }
    },
    initializeCheckboxes() {
      this.tableHeaderCheckbox = { [this.currentPage]: false }
      this.isSelected = {}

      // Checkbox values need to be set explicitly to trigger checkbox v-model
      // update
      const startRow = (this.currentPage - 1) * this.pageSize + 1
      const endRow = startRow + this.data.length - 1

      for (let row = startRow; row <= endRow; row++) {
        this.isSelected[row] = false
      }

      this.rowsSelected(this.getSelectedRows())
    },
    handleUpdate() {
      this.moveTableBodyAboveTableHeaders()
      this.initializeResizer()
    },
    emitSort(header) {
      if (!header.sortable) {
        return
      }

      const sortField = header.id
      const sortOrder =
        this.sortField !== sortField || this.sortOrder !== 'asc'
          ? 'asc'
          : 'desc'

      this.$emit('sort', { sortField, sortOrder })
    },
    makeColumnGroupClass(header) {
      let className = ''

      if (header.sortable && this.sortField === header.id) {
        className += ' sortedColumn'
      }

      return className
    },
    makeHeaderClass(header) {
      let className = ''

      if (header.sortable) {
        className = 'sortable column-base'

        if (this.sortField === header.id) {
          className += ` ${this.sortOrder}ending sortedColumn`
        }
      }

      if (header.class) {
        className += ` ${header.class}`
      }

      if (header.id === 'index') {
        className += ' minimal-width'
      } else {
        if (className !== '') {
          className += this.rightAlignedClass(header.id)
        } else {
          className = this.rightAlignedClass(header.id).trim()
        }
      }

      return className
    },
    rightAlignedClass(header) {
      let className = ''

      const fields = numericalFields.find(field => field === header)

      if (fields) {
        className = ' numerical-column'
      }

      return className
    },
    selectAllCheckbox() {
      const min = (this.currentPage - 1) * this.pageSize + 1
      const max = Math.min(
        this.currentPage * this.pageSize,
        (this.currentPage - 1) * this.pageSize + this.data.length
      )
      if (this.selectedHeaderCheckbox) {
        for (let row = min; row <= max; row++) {
          this.isSelected[row] = true
        }
      } else {
        for (let row = min; row <= max; row++) {
          this.isSelected[row] = false
        }
      }

      this.rowsSelected(this.getSelectedRows())
    },
    unselectRows() {
      Object.keys(this.isSelected).forEach(selected => {
        this.isSelected[selected] = false
      })
    },
    handleShiftSelection(rowNum) {
      const upperBound = Math.max(rowNum, this.shiftSelectionPivot)
      const lowerBound = Math.min(rowNum, this.shiftSelectionPivot)
      const toSelect = this.isSelected[rowNum]
      for (let row = lowerBound; row <= upperBound; row++) {
        this.isSelected[row] = toSelect
      }
      this.shiftSelectionPivot = rowNum
    },
    handleCurrentSelection(row) {
      const rowNum = parseInt(row)
      if (
        this.$keyboardEvents.isPressed(KEYS.SHIFT) &&
        this.shiftSelectionPivot !== void 0
      ) {
        this.handleShiftSelection(rowNum)
      } else {
        this.shiftSelectionPivot = rowNum
        this.shiftSelectionBounds.min = rowNum
        this.shiftSelectionBounds.max = rowNum
      }

      const min = (this.currentPage - 1) * this.pageSize + 1
      const max = Math.min(
        this.currentPage * this.pageSize,
        (this.currentPage - 1) * this.pageSize + this.data.length
      )
      const selectedRows = this.getSelectedRows()
      const currentPageRows = selectedRows.filter(rowNumber => {
        return rowNumber >= min && rowNumber <= max
      })

      if (!this.isSelected[row]) {
        this.tableHeaderCheckbox[this.currentPage] = false
      } else if (currentPageRows.length === this.pageSize) {
        this.tableHeaderCheckbox[this.currentPage] = true
      }
      this.rowsSelected(selectedRows)

      /*
        Force the component to re-render.
        Since changes in value of an object property is not detected.
      */
      this.$forceUpdate()
    },
    getSelectedRows() {
      let selectedRows = []

      for (const row in this.isSelected) {
        if (this.isSelected[row]) {
          selectedRows.push(row)
        }
      }

      return selectedRows
    },
    rowsSelected(rows) {
      this.$emit('rows-selected', rows)
    },
    calculateRowIndex(index) {
      return (this.currentPage - 1) * this.pageSize + 1 + index
    }
  }
}
</script>
<style src="~/assets/scss/components/data-viewer/data-table.scss" lang="scss" />
