<template>
  <info-navigation
    :stats="stats"
    :options="options"
    :stat="selectedStat"
    :date-range="dateRange"
    :type="'company'"
    :is-show-pop-over="isShowPopOver"
    :interval-scale= "intervalScale"
    @stat="update('setStat', $event)"
    @daterange="update('changeDateRange', $event)" 
    @dateInterval="dateInterval"/>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import InfoNavigation from '~/components/common/InfoNavigation'

export default {
  name: 'CompanyInfoNavigation',
  components: { InfoNavigation },
  props: {
    intervalScale: {
      required: true,
      type: String
    },
    tab: {
      type: String,
      default: '',
      validator(value) {
        switch (value) {
          case 'consignee':
          case 'shipper':
          case '':
            return true
          default:
            return false
        }
      }
    }
  },
  computed: {
    ...mapState('company', ['stat', 'shipmentsData', 'dateRange']),
    options() {
      return { dropdown: this.stats.length > 1 }
    },
    selectedStat() {
      return this.tab || this.stat
    },
    stats() {
      const stats = [
        {
          label: 'All Shipments',
          id: 'all',
          count: this.shipmentsData.all.totalCount
        },
        {
          label: 'Total Shipments as Importer',
          id: 'shipper',
          count: this.shipmentsData.shipper.totalCount
        },
        {
          label: 'Total Shipments as Supplier',
          id: 'consignee',
          count: this.shipmentsData.consignee.totalCount
        },
        {
          label: 'Total Internal Shipments',
          id: 'internal',
          count: this.shipmentsData.internal.totalCount
        }
      ]

      if (!this.tab) {
        return stats
      }

      for (let i = 0; i < stats.length; i++) {
        const stat = stats[i]
        if (stat.id === this.tab) {
          return [stat]
        }
      }
    },
    isShowPopOver() {
      return !(this.tab == 'shipper' || this.tab == 'consignee')
    }
  },
  methods: {
    ...mapMutations('company', ['setStat']),
    ...mapActions('company', ['changeDateRange']),

    dateInterval(interval) {
      this.$emit('dateInterval', interval)
    },
    update(action, value) {
      this[action](value)

      // shipments.shipments data is not cached for all types [all, consignee, shipper, internal]
      // because it's too memory intensive, and paginated.
      if (action === 'setStat') {
        this.$store.dispatch('company/shipments/initialize')
      }
    }
  }
}
</script>
