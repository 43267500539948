<template>
  <div class="pie-chart-card">
    <chart
      ref="pieChart"
      :class="pieClass"
      :data="data"
      :colors="colors"
      :labels="labels"/>
    <pie-chart-legend
      v-show="labels.length > 0"
      :legend="legendData"
      @hover="onLegendHover"/>
  </div>
</template>
<script>
import PieChartLegend from './PieChartLegend.vue'
import Chart from './Chart'

export default {
  name: 'PieChart',
  components: {
    Chart,
    PieChartLegend
  },
  props: {
    pieClass: {
      type: String,
      default: ''
    },
    legend: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      colors: ['#68d47b', '#7F93FF', '#6E44D7', '#F5B041', '#EC7063', '#2fadee']
    }
  },
  computed: {
    labels() {
      return this.legend.map(({ label }) => label)
    },
    legendData() {
      return this.legend.map((label, index) => {
        return {
          ...label,
          color: this.colors[index]
        }
      })
    }
  },
  methods: {
    onLegendHover(index) {
      if (!this.$refs.pieChart) {
        return
      }
      const chart = this.$refs.pieChart.$data._chart
      const data = chart.getDatasetMeta(0).data
      const slice =
        index === -1
          ? null
          : index < data.length
            ? data[index]
            : data[data.length - 1]

      const active = slice ? [slice] : []
      this.$refs.pieChart.activeElements = active
      chart.tooltip._active = active
      chart.tooltip.update(true)
      chart.render()
    }
  }
}
</script>
