export default {
  methods: {
    render() {
      if (this.$data._chart) {
        this.$data._chart.destroy()
      }
      this.renderChart(this.chartData, this.mergedOptions)
    }
  },
  mounted() {
    this.render()
  },
  watch: {
    chartData() {
      this.render()
    }
  }
}
