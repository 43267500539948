<template>
  <div
    :class="{ 'card-base': FirstLoginTour.show }"
    class="data-viewer">
    <data-viewer-header
      v-if="showDataViewerHeader"
      :show-cards-icon="options.showCardsIcon"
      :show-full-screen="inFullscreen"
      :show-full-screen-icon="options.showFullScreenIcon"
      :show-custom-view="showCustomView"
      :view-type="$store.state.dataViewer.viewType"
      @view-type="$store.commit('dataViewer/setViewType', $event)"
      @full-screen="setInFullscreen"
      @view-modal="viewModal">
      <slot
        slot="headerLeft"
        name="headerLeft" />

      <slot
        slot="headerRight"
        name="headerRight" />
    </data-viewer-header>
    <div
      v-loading="loading && !isComponentLoading"
      :class="`${$store.state.dataViewer.viewType}-view`">
      <data-table
        v-if="!options.showCards || $store.state.dataViewer.viewType === 'table'"
        :data="data"
        :total="total"
        :parent-name="parentName"
        :is-visible="isVisible"
        :headers="headers"
        :sort-field="sortField"
        :sort-order="sortOrder"
        :current-page="currentPage"
        :page-size="pageLimit"
        :show-search-icon="options.showSearchIcon"
        :table-wrapper-active-class="tableWrapperActiveClass"
        :table-class="tableClass"
        :has-checkboxes="hasCheckboxes"
        :start-index="startIndex"
        :disabled-column-resizer="disabledColumnResizer"
        :floating-scrollbar-options="floatingScrollbarOptions"
        @sort="$emit('sort', $event)"
        @rows-selected="$emit('rows-selected', $event)"/>
      <shipments-cards
        v-else
        :shipments="shipments" />
    </div>
    <div
      class="
      card-footer">
      <div class="footer-spacer"/>
      <el-pagination
        v-show="data.length > 0 && !hidePagination"
        :current-page="currentPage"
        :page-sizes="pageLimits"
        :page-size="pageLimit"
        :total="total"
        :limit="limit"
        :data="data"
        class=""
        @size-change="$emit('limit', $event)"
        @current-change="$emit('page', $event)"/>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex'
import DataTable from './DataTable'
import DataViewerHeader from './DataViewerHeader/DataViewerHeader'
import ShipmentsCards from './ShipmentsCards/ShipmentsCards'
import dataViewerProps from './data-viewer-props'
import { openUpgradeModal } from '~/mixins/upgradeModal/methods'

export default {
  name: 'DataViewer',
  components: {
    ShipmentsCards,
    DataTable,
    DataViewerHeader
  },
  mixins: [openUpgradeModal],
  props: {
    ...dataViewerProps,
    options: {
      type: Object,
      default() {
        return {}
      }
    },
    // table props
    headers: {
      type: Array,
      required: true
    },
    data: {
      type: Array,
      required: true
    },
    query: {
      type: String,
      default: ''
    },
    shipments: {
      type: Array,
      default: undefined
    },
    pageLimits: {
      type: Array,
      default() {
        return [20, 40, 60, 100]
      }
    },
    tableWrapperActiveClass: {
      type: Boolean,
      default: false
    },
    tableClass: {
      type: Object,
      default() {
        return {}
      }
    },
    disabledColumnResizer: {
      type: Array,
      default() {
        return []
      }
    },
    hidePagination: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    ...mapState(['inFullscreen']),
    ...mapState('search', ['isComponentLoading']),
    ...mapState('tours', ['FirstLoginTour']),

    startIndex() {
      return this.pageLimit * (this.currentPage - 1)
    },

    showDataViewerHeader() {
      return !(
        this.$route.path.includes('/search') ||
        this.$route.path.includes('/shipment-alert')
      )
    }
  },
  created() {
    // Validate shipments if cardView is enabled
    if (this.options.showCards && !this.shipments) {
      throw new Error('Shipments prop is required to show cards.')
    }
  },
  methods: {
    ...mapMutations(['setInFullscreen']),
    viewModal(open) {
      this.$emit('view-modal', open)
    }
  }
}
</script>
<style src="~/assets/scss/components/data-viewer/data-viewer.scss" lang="scss"/>
<style src="~/assets/scss/components/data-viewer/table-view.scss" lang="scss" />
<style src="~/assets/scss/components/data-viewer/cards-view.scss" lang="scss" />
<style src="~/assets/scss/sections/search/search-view.scss" lang="scss" />
