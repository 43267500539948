<template>
  <fieldset>
    <el-radio-group
      v-model="datePickerScale"
      class="el-radio-group el-radio-button-group">
      <el-radio-button
        v-for="datePickerScaleOption in datePickerScaleOptions"
        :key="datePickerScaleOption.scale"
        :label="datePickerScaleOption.scale"

        class="el-radio-button">
        {{ datePickerScaleOption.text }}
      </el-radio-button>
    </el-radio-group>
  </fieldset>
</template>

<script>
export default {
  name: 'DatePickerScale',
  props: {
    interval: {
      required: true,
      type: String,
      default() {
        return 'days'
      }
    }
  },
  data() {
    return {
      datePickerScaleOptions: [
        {
          scale: 'years',
          text: 'Yearly'
        },
        {
          scale: 'months',
          text: 'Monthly'
        },
        {
          scale: 'weeks',
          text: 'Weekly'
        },
        {
          scale: 'days',
          text: 'Daily'
        }
      ]
    }
  },
  computed: {
    datePickerScale: {
      get() {
        return this.interval
      },
      set(interval) {
        this.$emit('dateInterval', interval)
      }
    }
  }
  // methods: {
  //   ...mapActions('search', ['fetchShipmentGraph']),
  //   isDisabled(scale) {
  //     return (
  //       (scale === 'days' &&
  //         (this.selectedDatePreset === '6M' ||
  //           this.selectedDatePreset === '1Y' ||
  //           this.selectedDatePreset === '3Y' ||
  //           this.selectedDatePreset === '')) ||
  //       (scale === 'weeks' &&
  //         (this.selectedDatePreset === '' || this.selectedDatePreset === '3Y'))
  //     )
  //   }
  // }
}
</script>
