<template>
  <div>
    <company-info-navigation 
      :interval-scale="dateInterval" 
      tab="consignee" />

    <template v-if="consignees.length">
      <div class="stats-graph">
        <div class="chart-row pie-chart-row">
          <top-companies-chart company-type="consignees" />
          <top-ports-chart port-type="destinations" />
        </div>
      </div>

      <companies-data-viewer
        :class="{ 'full-screen': inFullscreen }"
        :loading="$actions.pending('company/importers/fetchCompanyConsignees')"
        :companies="consignees"
        :current-page="currentPage"
        :total="totalConsignees"
        company-type="consignee"
        @page="setCurrentPage"/>
    </template>
    <company-placeholder
      v-else
      :loading="$actions.pending('company/importers/fetchCompanyConsignees')"/>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import CompanyInfoNavigation from '~/components/company/CompanyInfoNavigation'
import TopCompaniesChart from '~/components/company/overview/TopCompaniesChart'
import TopPortsChart from '~/components/company/overview/TopPortsChart'
import CompaniesDataViewer from '~/components/data-viewers/CompaniesDataViewer'
import CompanyPlaceholder from '~/components/company/CompanyPlaceholder'

export default {
  name: 'ConsigneesPage',
  components: {
    CompanyInfoNavigation,
    TopCompaniesChart,
    TopPortsChart,
    CompaniesDataViewer,
    CompanyPlaceholder
  },
  computed: {
    ...mapState(['inFullscreen']),
    ...mapState('company/importers', [
      'consignees',
      'totalConsignees',
      'currentPage'
    ]),
    ...mapState('company', ['pageLimit', 'dateInterval'])
  },
  watch: {
    pageLimit() {
      this.updateQueryParams()
    },
    async $route(to) {
      const { p: page, r: limit } = to.query

      if (parseInt(page) !== this.currentPage) {
        this.setCurrentPage(parseInt(page))
      }
      if (parseInt(limit) !== this.pageLimit) {
        this.setPageLimit(parseInt(limit))
      }
    }
  },
  mounted() {
    this.updateQueryParams()
  },
  updated() {
    this.updateQueryParams()
  },
  methods: {
    ...mapMutations('company', ['setPageLimit']),
    ...mapMutations('company/importers', ['setCurrentPage']),
    updateQueryParams() {
      if (this.$actions.pending('company/importers/fetchCompanyConsignees')) {
        return
      }

      const pathWithQueryParams = {
        path: this.$route.path,
        query: { p: this.currentPage, r: this.pageLimit }
      }

      this.$router.push(pathWithQueryParams)
    }
  }
}
</script>
<style src="~/assets/scss/sections/company/overview.scss" lang="scss"/>
