<template>
  <div :class="['card-head-bar', {'bottom-shadow': !disabledDatePicker}]">
    <component
      :is="isDropdown ? 'dropdown' : 'tabs'"
      :selected="stat"
      :choices="stats"
      @choose="selectStat"/>
    <div class="head-toolbar">
      <date-range-selector
        :disabled-date-picker="disabledDatePicker"
        :date-ranges="dateRangePresets"
        :selected-range="dateRange"
        :min-date="minDate"
        :max-date="maxDate"
        :class="{ 'active': NoResultsTour.currentStep === 0 }"
        class="no-results-tour-0"
        @daterange="setDateRange"/>
      <el-popover
        v-if="isShowPopOver"
        :disabled="disabled"
        placement="bottom"
        width="300"
        trigger="click"
        popper-class="granularity-options is-far-right">
        <div class="popover-content">
          <div class="pop-over-default">
            <h2>Refine Chart</h2>
            <date-picker-scale 
              :interval="intervalScale" 
              @dateInterval="dateInterval"/>
              <!-- <fieldset>
              <hide-incomplete-data-checkbox />
              <autoscale-axis />
            </fieldset> -->
          </div>
        </div>
        <i
          slot="reference"
          :class="{disabled: disabled}"
          class="other-options-icon" />
      </el-popover>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import DateRangeSelector from '~/components/common/DateRangeSelector/DateRangeSelector'
import Dropdown from './Dropdown'
import Tabs from './Tabs'
import DatePickerScale from '~/components/common/DateRangeSelector/DatePickerScale'
import HideIncompleteDataCheckbox from '~/components/search/HideIncompletedataCheckbox'
import AutoscaleAxis from '~/components/search/AutoscaleAxis'
import TimePeriod from '~/utils/TimePeriod'

export default {
  components: {
    HideIncompleteDataCheckbox,
    AutoscaleAxis,
    DateRangeSelector,
    DatePickerScale,
    Dropdown,
    Tabs
  },
  props: {
    intervalScale: {
      required: true,
      type: String
    },
    options: {
      type: Object,
      default: () => ({})
    },
    stats: {
      type: Array,
      required: true
    },
    stat: {
      type: String,
      required: true
    },
    dateRange: {
      type: Object,
      required: true
    },
    type: {
      type: String,
      default: 'search'
    },
    isShowPopOver: {
      type: Boolean,
      default: () => true
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    disabledDatePicker: {
      type: Boolean,
      default: () => false
    }
  },
  computed: {
    ...mapState('tours', ['NoResultsTour']),
    ...mapState(['minDate', 'maxDate']),
    isDropdown() {
      return this.options && this.options.dropdown
    },
    dateRangePresets() {
      const allPresetMinDate = TimePeriod.subtractDateRangeFromTimestamp(
        this.maxDate,
        '5 years'
      )
      return [
        ...this.$date.getCommonDateRanges(this.maxDate),
        { label: 'ALL', text: 'ALL', from: allPresetMinDate, to: this.maxDate }
      ]
    }
  },
  methods: {
    dateInterval(interval) {
      this.$emit('dateInterval', interval)
    },
    selectStat(stat) {
      if (this.stat !== stat) {
        this.$emit('stat', stat)
      }
    },
    setDateRange(dateRange) {
      const { from, to } = dateRange
      dateRange.from = this.$date.getUtcDate(from).getTime()
      dateRange.to = this.$date.getUtcDate(to).getTime()

      if (JSON.stringify(this.dateRange) !== JSON.stringify(dateRange)) {
        this.$emit('daterange', dateRange)
      }

      this.noResultsTourNextStep()
    },
    noResultsTourNextStep() {
      const NoResultsTour = this.$tours.NoResultsTour
      if (
        NoResultsTour &&
        NoResultsTour.isRunning &&
        NoResultsTour.currentStep === 0
      ) {
        NoResultsTour.nextStep()
      }
    }
  }
}
</script>
