import { numberWithLetters } from '../../../utils/number'

export default {
  props: {
    selected: {
      type: String,
      required: false
    },
    choices: {
      type: Array,
      required: true
    }
  },

  computed: {
    selectedChoice() {
      return this.choices.find(choice => choice.id === this.selected)
    },
    availableChoices() {
      return this.choices.filter(choice => choice.id !== this.selected)
    }
  },

  methods: {
    choose({ id }) {
      this.$emit('choose', id)
    },
    weight(kgs) {
      if (kgs < 10000) return { value: kgs, suffixes: [' kg'] }
      const { value, suffix } = numberWithLetters(kgs / 907.1847, 2, true)
      return { value, suffixes: [suffix, 'tons'] }
    },
    displayChoice(choice) {
      let decimals = 2
      switch (choice.id) {
        case 'weight':
          return this.weight(choice.count)
        case 'all':
        case 'shipper':
        case 'consignee':
        case 'internal':
          decimals = 0
          break
        default:
          decimals = 2
      }

      const { value, suffix } = numberWithLetters(choice.count, decimals, true)
      return { value, suffixes: [suffix] }
    }
  }
}
